import { createStore } from 'redux';
import { reactReduxFirebase } from 'react-redux-firebase';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reduxFirestore } from 'redux-firestore'
import * as firebase from 'firebase/app';

import rootReducer from './reducers';
import API from '../API';
import initialState from './initState';

const rrfConfig = {
  userProfile: null,
  useFirestoreForProfile: true
};

const createStoreWithFirebase = composeWithDevTools(
  reduxFirestore(firebase),
  reactReduxFirebase(API, rrfConfig),
)(createStore);

const store = createStoreWithFirebase(rootReducer, initialState);

export default store;
