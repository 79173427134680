import React, { Component, Fragment } from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  withStyles
} from '@material-ui/core';

import RoundView from '../components/RoundView';

import API from '../API';
import { clone } from '../utils';

const styles = {
  status: {
    marginLeft: 'auto',
    width: '140px'
  },

  textField: {
    width: '200px'
  },

  header: {
    display: 'flex'
  },

  info: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '20px'
  },

  submitButton: {
    width: '200px',
    marginTop: '10px'
  },

  addRoundButton: {
    marginLeft: '10px'
  },

  controlButtons: {
    display: 'flex',
    alignItems: 'center'
  },

  submitLoading: {
    width: '200px',
    height: '36px',
    margin: '10px 0 0 10px'
  }
};

class OrderData extends Component {
  state = {
    clientName: '',
    companyName: '',
    createDate: null,
    displayName: '',
    id: '',
    pickupFrom: '',
    products: [],
    sendDate: '',
    status: 'new',
    uid: '',
    isOnSave: false
  };

  getStatus = () => {
    const { data } = this.props;
    const variants = {
      new: 'Запрос',
      active: 'Активный',
      done: 'Выполненный'
    };
    return variants[data.status];
  };

  setValue = (e, key) => {
    this.setState({
      [key]: e.target.value
    });
  };

  componentDidMount() {
    this.setState({ ...this.props.data });
  }

  addRound = () => {
    const products = clone(this.state.products);

    products.push({});

    this.setState({
      products
    });
  };

  saveData = () => {
    const db = API.firestore();

    this.setState({
      isOnSave: true
    });

    const sendData = { ...this.state };
    delete sendData.isOnSave;
    const products = [];
    for (let i = 0; i < sendData.products.length; i += 1) {
      const data = this[`ref${i}`].current.state;
      data.totalVolume =
        data.cartonsData.length > 0
          ? data.cartonsData
              .map(
                carton =>
                  ((parseFloat(carton.l) *
                    parseFloat(carton.w) *
                    parseFloat(carton.h)) /
                    1000000) *
                  carton.cartonsQty
              )
              .reduce((prev, cur) => prev + cur)
              .toFixed(3)
          : 0;
      data.totalWeight =
        data.cartonsData.length > 0
          ? data.cartonsData
              .map(
                carton =>
                  parseFloat(carton.cartonWeight) *
                  parseFloat(carton.cartonsQty)
              )
              .reduce((prev, cur) => prev + cur)
              .toFixed(3)
          : 0;
      data.volumeWeight =
        (data.cartonsData.length > 0
          ? data.cartonsData
              .map(
                carton =>
                  ((parseFloat(carton.l) *
                    parseFloat(carton.w) *
                    parseFloat(carton.h)) /
                    6000) *
                  carton.cartonsQty
              )
              .reduce((prev, cur) => prev + cur)
          : 0
        ).toFixed(3) + ' CBM';

      data.volumeWeightExpress =
        (data.cartonsData.length > 0
          ? data.cartonsData
              .map(
                carton =>
                  ((parseFloat(carton.l) *
                    parseFloat(carton.w) *
                    parseFloat(carton.h)) /
                    5000) *
                  carton.cartonsQty
              )
              .reduce((prev, cur) => prev + cur)
          : 0
        ).toFixed(3) + ' CBM';

      products.push(data);
    }

    sendData.products = products;

    db.collection('orders')
      .where('id', '==', this.state.id)
      .get()
      .then(querySnap => {
        querySnap.forEach(doc => {
          db.collection('orders')
            .doc(doc.id)
            .update(sendData)
            .then(() => {
              this.setState({
                isOnSave: false
              });
            });
        });
      });
  };

  setStatus = e => {
    this.setState({
      status: e.target.value
    });
  };

  deleteRound = index => {
    const products = clone(this.state.products);

    products.splice(index, 1);

    this.setState({
      products
    });
  };

  render() {
    const { classes } = this.props;
    const {
      clientName,
      pickupFrom,
      status,
      id,
      products,
      isOnSave
    } = this.state;

    return (
      <Grid container>
        <div className={classes.info}>
          <div className={classes.header}>
            <TextField
              label="Client Name"
              className={classes.textField}
              value={clientName}
              onChange={e => this.setValue(e, 'clientName')}
            />
            <FormControl className={classes.status}>
              <InputLabel htmlFor="age-simple">Статус:</InputLabel>
              <Select value={status} onChange={this.setStatus}>
                <MenuItem value="new">Запрос</MenuItem>
                <MenuItem value="active">Активный</MenuItem>
                <MenuItem value="done">Завершенный</MenuItem>
              </Select>
            </FormControl>
          </div>
          <TextField
            label="Pickup From"
            className={classes.textField}
            value={pickupFrom}
            onChange={e => this.setValue(e, 'pickupFrom')}
          />
          <div className={classes.controlButtons}>
            {isOnSave ? (
              <Grid
                justify="center"
                container
                className={classes.submitLoading}
              >
                <CircularProgress size={36} />
              </Grid>
            ) : (
              <Button
                variant="contained"
                className={classes.submitButton}
                color="primary"
                onClick={this.saveData}
              >
                Сохранить данные
              </Button>
            )}

            <Button
              variant="contained"
              className={`${classes.submitButton} ${classes.addRoundButton}`}
              color="primary"
              onClick={this.addRound}
            >
              Добавить Рейс
            </Button>
          </div>
        </div>
        {products.map((product, index) => {
          this[`ref${index}`] = React.createRef();
          return (
            <Fragment key={index}>
              <RoundView
                ref={this[`ref${index}`]}
                product={{ ...product }}
                clientName={clientName}
                pickupFrom={pickupFrom}
                id={id}
                num={index + 1}
                deleteRound={this.deleteRound}
                saveData={this.saveData}
              />
            </Fragment>
          );
        })}
      </Grid>
    );
  }
}

export default withStyles(styles)(OrderData);
