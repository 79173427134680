import React, { Component, Fragment } from 'react';
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  withStyles
} from '@material-ui/core';
import Create from '@material-ui/icons/Create';
import Done from '@material-ui/icons/Done';
import Close from '@material-ui/icons/Close';
import Copy from '@material-ui/icons/FileCopy';

import { format } from 'date-fns';

import { clone, formatShippingMethod } from '../utils';
import DetailsEdit from './DetailsEdit';
import API from '../API';

const getDetails = (offer, totalVolume, invoiceAmount) => {
  const { type } = offer;

  if (type === 'DDP') {
    const { pickupCost, priceKg, qtyKg } = offer;

    return `
    <b>Цена КГ:</b> $${priceKg},
    <b>Кол-во КГ:</b> ${qtyKg},
    <b>Фрахт:</b> $${(parseFloat(priceKg) * parseFloat(qtyKg)).toFixed(2)},
    <b>PickUp:</b> $${pickupCost}
    `;
  } else if (type === 'DDU') {
    const { cbmCost, dutyRate, pickupCost, volume } = offer;

    const freight = parseFloat(totalVolume) * cbmCost;
    const tax = (parseFloat(invoiceAmount) / 100) * dutyRate;

    return `
    <b>Цена CBM:</b> $${cbmCost},
    <b>Объем:</b> ${volume || totalVolume},
    <b>Фрахт:</b> $${freight.toFixed(2)},
    <b>% сборов:</b> ${parseFloat(dutyRate).toFixed(2)}%,
    <b>Сбор:</b> $${tax.toFixed(2)},
    <b>PickUp:</b> $${pickupCost}
    <b>Итог. сумма:</b> $${(freight + tax + parseFloat(pickupCost)).toFixed(2)},
    `;
  }
};

const styles = {
  tableInput: {
    boxShadow: 'none',
    border: 'none',
    textAlign: 'center',
    fontSize: '0.875rem',
    '&:focus': {
      outline: 'none'
    }
  },

  tableCell: {},

  copyIcon: {
    cursor: 'pointer'
  },

  input: {
    display: 'flex',
    '& > input': {
      width: '50px'
    }
  },

  detailsCell: {
    whiteSpace: 'pre',
    '& > svg': {
      opacity: 0
    },
    '&:hover': {
      '& > svg': {
        opacity: 1
      }
    }
  },

  selected: {
    background: '#4caf50',
    '& > td': {
      color: 'white'
    },
    '& div': {
      color: 'white'
    },
    '& input': {
      background: '#4caf50',
      color: 'white'
    }
  },

  editButton: {
    color: 'grey',
    fontSize: '18px',
    cursor: 'pointer'
  },

  textCentered: {
    textAlign: 'center',
    width: '50px'
  }
};

class OfferRow extends Component {
  state = {
    cost: '',
    ourPrice: '',
    qty: '',
    type: '',
    editedDetails: false,
    ourPickUp: ''
  };

  setValue = (e, key) => {
    this.setState({
      [key]: e.target.value
    });
  };

  componentDidMount() {
    const { offer, product } = this.props;
    const { priceKg, qtyKg, cbmCost } = offer;

    const totalVolume = product.totalVolume;

    if (offer.userData !== undefined) {
      this.setState(offer.userData);
    } else {
      this.setState({
        cost:
          offer.type !== 'DDU'
            ? parseFloat(priceKg) * parseFloat(qtyKg)
            : parseFloat(totalVolume) * cbmCost,
        type: offer.type === 'DDP' ? 'KG' : 'CBM'
      });
    }
  }

  changeEditMode = () => {
    const { editedDetails } = this.state;

    this.setState({
      editedDetails: !editedDetails
    });
  };

  setDeal = mode => {
    const { index, data, productIndex, getData, setDone, save } = this.props;
    save();
    const db = API.firestore();

    const newData = clone(data);
    const offer = newData.products[productIndex].offerList[index];
    offer.done = mode;
    offer.userData = offer.userData || {};

    db.collection('orders')
      .where('id', '==', data.id)
      .get()
      .then(querySnap => {
        querySnap.forEach(doc => {
          db.collection('orders')
            .doc(doc.id)
            .update(newData)
            .then(() => getData())
            .then(() => {
              setDone(mode);

              return db
                .collection('partners')
                .where('email', '==', offer.transporter)
                .get();
            })
            .then(partnersQuery => {
              return partnersQuery.forEach(partner => {
                const data = partner.data();
                if (mode) {
                  if (data.offerHistory) {
                    data.offerHistory.push({
                      id: newData.id,
                      productIndex,
                      clientName: newData.clientName,
                      createdDate: format(
                        newData.createDate.seconds * 1000,
                        'dd/mm/yyyy'
                      ),
                      profit:
                        parseFloat(offer.userData.ourPrice) *
                          parseFloat(offer.userData.qty) -
                        parseFloat(offer.userData.cost),
                      status: offer.status || 'Не оплачено'
                    });
                  } else {
                    data.offerHistory = [];
                    data.offerHistory.push({
                      id: newData.id,
                      productIndex,
                      clientName: newData.clientName,
                      createdDate: format(
                        newData.createDate.seconds * 1000,
                        'dd/mm/yyyy'
                      ),
                      profit:
                        parseFloat(offer.userData.ourPrice) *
                          parseFloat(offer.userData.qty) -
                        parseFloat(offer.userData.cost),
                      status: offer.status || 'Не оплачено'
                    });
                  }
                } else {
                  data.offerHistory.splice(
                    data.offerHistory.filter(
                      offer => offer.productIndex === productIndex
                    )[0],
                    1
                  );
                }

                db.collection('partners')
                  .doc(partner.id)
                  .update({
                    offerHistory: data.offerHistory
                  })
                  .then(() => {});
              });
            });
        });
      });
  };

  setClipboard = () => {
    const { product, offer, data } = this.props;
    const { ourPrice, ourPickUp, type, qty } = this.state;

    const weight = product.cartonsData.map(
      carton => parseFloat(carton.cartonWeight) * parseFloat(qty)
    );
    const weightSum = weight.reduce((prev, cur) => prev + cur);

    const str = `
    Client : ${data.clientName}
    Product: ${product.productName}
    Shipping Method: ${formatShippingMethod(offer.shippingMethod, 'string')}
    Price per ${type}: $${ourPrice}
    
    Total: ${weightSum} ${type} * $${ourPrice} = $${(
      weightSum * ourPrice
    ).toFixed(2)}
    Goods PickUp: $${ourPickUp || ''}
    `;

    window.navigator.clipboard.writeText(str).then(() => {});
  };

  render() {
    const {
      offer,
      index,
      product,
      classes,
      productIndex,
      data,
      getData,
      alreadyDone
    } = this.props;
    const { cost, ourPrice, qty, editedDetails, type, ourPickUp } = this.state;
    const { done, pickupCost } = offer;

    let sum =
      (ourPrice && qty && parseFloat(ourPrice) * parseFloat(qty)) || null;

    const details = getDetails(
      offer,
      product.totalVolume,
      product.invoiceAmount
    );
    return (
      <TableRow
        key={`${offer.transporter}-${index}`}
        className={done ? classes.selected : ''}
      >
        <TableCell>{`${product.allPartners.filter(
          partner => partner.email === offer.transporter
        )[0] &&
          product.allPartners.filter(
            partner => partner.email === offer.transporter
          )[0].name} ${offer.transporter}`}</TableCell>
        <TableCell className={classes.detailsCell}>
          {editedDetails ? (
            <DetailsEdit
              getData={getData}
              offer={offer}
              index={index}
              productIndex={productIndex}
              data={data}
              productData={{
                totalVolume: product.totalVolume,
                invoiceAmount: product.invoiceAmount
              }}
              handleClose={this.changeEditMode}
            />
          ) : (
            <Fragment>
              <div dangerouslySetInnerHTML={{ __html: details }} />
              <Create
                onClick={this.changeEditMode}
                className={classes.editButton}
              />
            </Fragment>
          )}
        </TableCell>
        <TableCell style={{ borderRight: '3px solid' }}>
          {formatShippingMethod(offer.shippingMethod, 'string')}
        </TableCell>
        <TableCell className={classes.tableCell}>
          <div className={classes.input}>
            <span>$</span>

            <input
              value={cost}
              type="number"
              onChange={e => this.setValue(e, 'cost')}
              className={classes.tableInput}
            />
          </div>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <FormControl className={classes.wideInput}>
            <Select value={type} onChange={e => this.setValue(e, 'type')}>
              <MenuItem value="KG">KG</MenuItem>
              <MenuItem value="CBM">CBM</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <div className={classes.input}>
            <span>$</span>
            <input
              value={ourPrice}
              type="number"
              onChange={e => this.setValue(e, 'ourPrice')}
              className={classes.tableInput}
            />
          </div>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <input
            value={qty}
            onChange={e => this.setValue(e, 'qty')}
            type="number"
            className={classes.tableInput}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <div className={classes.textCentered}>{`$${sum || 0}`}</div>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <div className={classes.input}>
            <span>$</span>
            <input
              value={ourPickUp}
              type="number"
              onChange={e => this.setValue(e, 'ourPickUp')}
              className={classes.tableInput}
            />
          </div>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <div className={classes.textCentered}>
            {`$${(sum &&
              cost &&
              (parseFloat(sum) - cost + (ourPickUp - pickupCost)).toFixed(2)) ||
              0}`}
          </div>
        </TableCell>
        <TableCell>
          <Copy className={classes.copyIcon} onClick={this.setClipboard} />
        </TableCell>
        <TableCell>
          {alreadyDone && !offer.done ? null : done ? (
            <Close onClick={() => this.setDeal(false)} />
          ) : (
            <Done onClick={() => this.setDeal(true)} />
          )}
        </TableCell>
        <TableCell>
          <Button
            onClick={() => this.props.deleteTender(index, productIndex)}
            variant="contained"
            color="primary"
          >
            Удалить
          </Button>
        </TableCell>
      </TableRow>
    );
  }
}

export default withStyles(styles)(OfferRow);
