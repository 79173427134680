import React, { Component } from 'react';
import {
  Button,
  CircularProgress,
  TableCell,
  TableRow,
  TextField,
  withStyles
} from '@material-ui/core';

const styles = {
  loading: {
    width: '93px',
    height: '36px'
  },

  clientForm: {
    display: 'flex',
    alignItems: 'flex-end'
  },

  form: {
    margin: '10px 0 10px 0'
  },

  textField: {
    margin: '0 5px 0 5px'
  }
};

class ClientAddForm extends Component {
  constructor(props) {
    super(props);

    this.initState = {
      clientName: '',
      companyName: '',
      email: '',
      skype: '',
      wechat: '',
      phone: '',
      country: '',
      isLoading: false
    };

    this.state = this.initState;
  }

  setValue = (val, key) => {
    this.setState({
      [key]: val
    });
  };

  addClient = () => {
    const {
      clientName,
      companyName,
      email,
      skype,
      wechat,
      phone,
      country
    } = this.state;

    this.setState({
      isLoading: true
    });

    const { genData, isTable, getUserList } = this.props;

    const name = clientName.split(' ');

    fetch('https://us-central1-blh-consulting.cloudfunctions.net/createUser', {
      method: 'POST',
      body: JSON.stringify({
        firstName: name[0],
        lastName: name[1] || '',
        email:
          email || `no-email${(Math.random() * 1000).toFixed()}@no-email.com`,
        skype,
        phone,
        country,
        wechat,
        companyName,
        createdDate: Date.now()
      })
    })
      .then(res => res.text())
      .then(() => {
        if (isTable) {
          genData();
        } else {
          getUserList(clientName);
        }
        this.setState(this.initState);
      });
  };

  setField = field => {
    const variants = {
      'Client Name': 'clientName',
      'Company Name': 'companyName',
      Email: 'email',
      Skype: 'skype',
      WeChat: 'wechat',
      Phone: 'phone',
      Country: 'country'
    };

    return variants[field];
  };

  render() {
    const { isTable, classes } = this.props;
    const { isLoading, clientName, country } = this.state;

    if (isTable) {
      return (
        <TableRow>
          <TableCell />
          {[
            'clientName',
            'companyName',
            'email',
            'skype',
            'wechat',
            'phone',
            'country',
            ''
          ].map(field => {
            return (
              <TableCell key={field}>
                <TextField
                  value={this.state[field]}
                  onChange={e => this.setValue(e.target.value, field)}
                />
              </TableCell>
            );
          })}
          <TableCell>
            {isLoading ? (
              <div className={classes.loading}>
                <CircularProgress size={36} />
              </div>
            ) : (
              <Button
                onClick={this.addClient}
                disabled={!clientName || !country}
              >
                Добавить
              </Button>
            )}
          </TableCell>
          <TableCell />
        </TableRow>
      );
    }

    if (!isTable) {
      return (
        <div className={`${classes.clientForm} ${classes.form}`}>
          {[
            'Client Name',
            'Company Name',
            'Email',
            'Skype',
            'WeChat',
            'Phone',
            'Country'
          ].map((field, index) => {
            return (
              <TextField
                className={classes.textField}
                key={`${field}-${index}`}
                label={field}
                value={this.state[this.setField(field)]}
                onChange={e =>
                  this.setValue(e.target.value, this.setField(field))
                }
              />
            );
          })}
          <div>
            {isLoading ? (
              <div className={classes.loading}>
                <CircularProgress size={36} />
              </div>
            ) : (
              <Button onClick={this.addClient}>Добавить</Button>
            )}
          </div>
        </div>
      );
    }
  }
}

export default withStyles(styles)(ClientAddForm);
