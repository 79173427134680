import React, { Component } from 'react';
import { Typography, Container, withStyles } from '@material-ui/core';

import LoginForm from '../components/LoginForm';
import RegisterForm from '../components/RegisterForm';

const style = () => ({
  form: {
    margin: '20px'
  }
});
class AuthPage extends Component {
  render() {
    const type = this.props.match.params[0];
    const { classes } = this.props;

    return (
      <Container component="main" maxWidth="xs">
        <Typography
          className={classes.form}
          component="h1"
          align="center"
          variant="h5"
        >
          {type === 'register' ? 'Register' : 'Login'}
        </Typography>
        {type === 'register' ? <RegisterForm /> : <LoginForm />}
      </Container>
    );
  }
}

export default withStyles(style)(AuthPage);
