import React from 'react';
import { AppBar, Toolbar, Typography, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import API from '../API';

const useStyles = makeStyles(theme => ({
  companyName: {
    marginLeft: '20px'
  },

  center: {
    display: 'flex',
    alignItems: 'center',
    width: '100vw',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 'auto'
    }
  },

  logout: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },

  name: {
    fontSize: 14,
    marginRight: '10px',
    cursor: 'default'
  }
}));

const Header = props => {
  const classes = useStyles();
  const { isEmptyUser, history, email } = props;

  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <div className={classes.center}>
          <img
            src="https://static.wixstatic.com/media/a2ecd5_cb452d3721b249b28592234aa432545a~mv2_d_3735_3735_s_4_2.png/v1/fill/w_60,h_60,al_c,q_80,usm_0.66_1.00_0.01/logo_no%20text.webp"
            alt="BLH Logo"
          />
          <Typography
            color="textPrimary"
            className={classes.companyName}
            variant="h6"
          >
            BLH-CONSULTING
          </Typography>
          {isEmptyUser ? null : (
            <div className={classes.logout}>
              <Typography color="textPrimary" className={classes.name}>
                {`Hi, user | ${email}`}
              </Typography>
              <Typography
                color="error"
                onClick={() =>
                  API.auth()
                    .signOut()
                    .then(() => history.push('/login'))
                }
                variant="h6"
              >
                Logout
              </Typography>
            </div>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = state => ({
  isEmptyUser: state.firebase.auth.isEmpty,
  email: state.firebase.auth.email,
  displayName: state.firebase.auth.displayName,
  uid: state.firebase.auth.uid
});

export default compose(
  connect(mapStateToProps),
  withRouter
)(Header);
