import React, { Component } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';

import UploadPhoto from './UploadPhoto';

import CloseIcon from '@material-ui/icons/Close';
import CopyIcon from '@material-ui/icons/FileCopy';

import API from '../API';
import { formatShippingMethod } from '../utils';

const styles = {
  mainContainer: {
    margin: '20px',
    paddingBottom: '5px',
    borderBottom: '2px solid'
  },

  carton: {
    marginTop: '5px',
    marginBottom: '5px',
    display: 'flex',
    flexDirection: 'column'
  },

  photo: {
    height: '50px',
    margin: '5px'
  },

  textField: {
    width: '200px'
  },

  cartonContainer: {
    display: 'flex'
  },

  squareField: {
    width: '50px',
    height: '50px'
  },

  copyBlock: {
    width: 'auto'
  },

  emailInput: {
    width: '400px'
  },

  copyIcon: {
    cursor: 'pointer'
  },

  cartonDel: {
    color: 'red',
    cursor: 'pointer'
  },

  cartonTitle: {
    display: 'flex',
    alignItems: 'center'
  },

  shippingMethod: {
    borderBottom: '1px solid'
  },

  emailContainer: {
    marginTop: '10px',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingTop: '10px',
    borderTop: '1px solid'
  },

  cartonSize: {
    marginTop: '5px',
    marginBottom: '5px'
  },

  cartonsControl: {
    '& button': {
      margin: '0 10px 10px 0'
    }
  },

  deletePhoto: {
    position: 'relative',
    top: '-45px',
    left: '-10px',
    cursor: 'pointer',
    color: 'red'
  }
};

class RoundView extends Component {
  state = {
    productName: '',
    hsCode: '',
    cartonsData: [],
    totalWeight: '',
    totalVolume: '',
    invoiceAmount: '',
    shipTo: '',
    photos: [],
    ra: false,
    rs: false,
    ms: false,
    ae1: false,
    ae2: false,
    partnerEmails: [],
    allPartnerEmails: [],
    allPartners: [],
    emailHeader: ''
  };

  componentDidMount() {
    this.setState({ ...this.props.product });

    const db = API.firestore();

    db.collection('partners')
      .get()
      .then(querySnap => {
        const data = querySnap.docs.map(doc => doc.data());
        this.setState({
          allPartnerEmails: data.map(partner => partner.email),
          allPartners: data
        });
      });
  }

  handleChange = key => () => {
    this.setState({
      [key]: !this.state[key]
    });
  };

  setValue = (e, key) => {
    this.setState({
      [key]: e.target.value
    });
  };

  setCartonData = (event, key, index) => {
    const { cartonsData } = this.state;

    const newCartonsData = [...cartonsData];
    newCartonsData[index][key] = event.target.value;

    this.setState({
      cartonsData: newCartonsData
    });
  };

  addEmail = event => {
    // const { options } = event.target;
    // const value = [];
    // for (let i = 0, l = options.length; i < l; i += 1) {
    //   if (options[i].selected) {
    //     value.push(options[i].value);
    //   }
    //}

    this.setState({
      partnerEmails: event.target.value
    });
  };

  sendEmail = isPreview => {
    const sendData = { ...this.state };
    const { saveData } = this.props;
    sendData.volumeWeight = parseFloat(sendData.volumeWeight);
    sendData.volumeWeightExpress = parseFloat(sendData.volumeWeightExpress);
    saveData();
    const db = API.firestore();
    sendData.partnerEmails.forEach((partner, index) => {
      if (!isPreview || (isPreview && index === 0)) {
        db.collection('partners')
          .where('email', '==', partner)
          .get()
          .then(querySnap => {
            querySnap.forEach(doc => {
              sendData.partnerName = doc.data().name;
              sendData.clientName = this.props.clientName;
              sendData.pickupFrom = this.props.pickupFrom;
              sendData.id = this.props.id;
              sendData.to = partner;
              sendData.isPreview = isPreview;
              sendData.totalVolume =
                sendData.cartonsData.length > 0
                  ? sendData.cartonsData
                      .map(
                        carton =>
                          ((parseFloat(carton.l) *
                            parseFloat(carton.w) *
                            parseFloat(carton.h)) /
                            1000000) *
                          carton.cartonsQty
                      )
                      .reduce((prev, cur) => prev + cur)
                      .toFixed(3)
                  : 0;
              sendData.totalWeight =
                sendData.cartonsData.length > 0
                  ? sendData.cartonsData
                      .map(
                        carton =>
                          parseFloat(carton.cartonWeight) *
                          parseFloat(carton.cartonsQty)
                      )
                      .reduce((prev, cur) => prev + cur)
                      .toFixed(3)
                  : 0;

              fetch(
                'https://us-central1-blh-consulting.cloudfunctions.net/sendPartnerEmail',
                {
                  method: 'POST',
                  body: JSON.stringify(sendData)
                }
              ).then(res => {
                if (isPreview) {
                  res.text().then(html => {
                    const wd = window.open();
                    wd.document.write(html);
                  });
                }
              });
            });
          });
      }
    });
  };

  addPhoto = (e, stopLoading) => {
    const { num, id } = this.props;

    const photos = [...e.target.files];

    Promise.all(
      photos.map(photo => {
        const storageRef = API.storage().ref(`${Date.now()}-${photo.name}`);

        return storageRef.put(photo);
      })
    ).then(snaps => {
      Promise.all(
        snaps.map(snap => {
          return snap.ref.getDownloadURL();
        })
      ).then(urls => {
        const formattedPhotosData = snaps.map((snap, index) => {
          return {
            name: snap.metadata.name,
            url: urls[index]
          };
        });

        const db = API.firestore();

        db.collection('orders')
          .where('id', '==', id)
          .get()
          .then(querySnap => {
            querySnap.forEach(doc => {
              const data = doc.data();
              const photos = data.products[num - 1].photos || [];
              photos.push(...formattedPhotosData);
              data.products[num - 1].photos = photos;

              db.collection('orders')
                .doc(doc.id)
                .update(data)
                .then(() => {
                  this.setState({
                    photos
                  });
                  stopLoading();
                });
            });
          });
      });
    });
  };

  deletePhoto = photo => {
    const { id, num } = this.props;

    const removedPhotoRef = API.storage().ref(photo.name);

    removedPhotoRef.delete().then(() => {
      const db = API.firestore();

      db.collection('orders')
        .where('id', '==', id)
        .get()
        .then(querySnap => {
          querySnap.forEach(doc => {
            const data = doc.data();
            const photos = data.products[num - 1].photos || [];
            photos.splice(photos.indexOf(photo), 1);
            data.products[num - 1].photos = photos;

            db.collection('orders')
              .doc(doc.id)
              .update(data)
              .then(() => {
                this.setState({
                  photos
                });
              });
          });
        });
    });
  };

  copyData = () => {
    const { clientName, pickupFrom } = this.props;
    const {
      productName,
      hsCode,
      cartonsData,
      totalWeight,
      totalVolume,
      volumeWeight,
      volumeWeightExpress,
      invoiceAmount,
      shipTo,
      ra,
      rs,
      ms,
      ae1,
      ae2
    } = this.state;

    const str = `
      Client Name: ${clientName};
      Pickup From: ${pickupFrom};
      Product Name: ${productName};
      HS Code: ${hsCode};
      ${cartonsData
        .map((carton, index) => {
          const { cartonWeight, cartonsQty, h, l, w } = carton;

          return `
          Carton: ${index + 1}: ${l} * ${h} * ${w};
          Carton Weight(KG): ${cartonWeight};
          Cartons Quantity: ${cartonsQty}
        `;
        })
        .join('\n')}
      Total Weight: ${parseFloat(
        parseFloat(totalWeight).toFixed(2)
      ).toString()} KG;
      Total Volume: ${parseFloat(totalVolume).toFixed(2)} CBM;
      Volume Weight: ${parseFloat(volumeWeight).toFixed(2)} KG;
      Volume Weight Express: ${parseFloat(volumeWeightExpress).toFixed(2)} KG;
      Invoice Amount(USD): ${invoiceAmount};
      Ship To: ${shipTo};
      Shipping Method: ${formatShippingMethod(
        { ae1, ae2, ms, ra, rs },
        'object'
      ).trim()}
    `;

    window.navigator.clipboard.writeText(str).then(() => {});
  };

  render() {
    const { product, num, classes } = this.props;
    const {
      productName,
      hsCode,
      cartonsData,
      invoiceAmount,
      shipTo,
      photos,
      ae2,
      ra,
      ms,
      rs,
      partnerEmails,
      allPartners,
      emailHeader
    } = this.state;

    return (
      <Grid container direction="column" className={classes.mainContainer}>
        <Typography variant="h6">
          {`Рейс ${num}`}
          <CloseIcon
            onClick={() => this.props.deleteRound(num - 1)}
            className={classes.cartonDel}
          />
        </Typography>
        <Grid container>
          <Grid container direction="column" className={classes.copyBlock}>
            <TextField
              value={productName}
              label="Product Name"
              className={classes.textField}
              onChange={e => this.setValue(e, 'productName')}
            />
            <TextField
              value={hsCode}
              label="HS Code"
              className={classes.textField}
              onChange={e => this.setValue(e, 'hsCode')}
            />
          </Grid>
          <div>
            <CopyIcon className={classes.copyIcon} onClick={this.copyData} />
          </div>
        </Grid>

        <div className={classes.cartonContainer}>
          {cartonsData.map((carton, index) => {
            return (
              <div key={index} className={classes.carton}>
                <Typography variant="subtitle1" className={classes.cartonTitle}>
                  {`Carton ${index + 1}:`}{' '}
                  <CloseIcon
                    onClick={() => {
                      const cartonsData = [...this.state.cartonsData];
                      cartonsData.splice(index, 1);

                      this.setState({
                        cartonsData
                      });
                    }}
                    className={classes.cartonDel}
                  />
                </Typography>
                <div className={classes.cartonSize}>
                  <Typography variant="subtitle2">Carton Size(cm): </Typography>
                  <TextField
                    value={carton.l}
                    label="L"
                    type="number"
                    className={classes.squareField}
                    onChange={e => this.setCartonData(e, 'l', index)}
                  />
                  <TextField
                    value={carton.w}
                    label="W"
                    type="number"
                    className={classes.squareField}
                    onChange={e => this.setCartonData(e, 'w', index)}
                  />
                  <TextField
                    value={carton.h}
                    label="H"
                    type="number"
                    className={classes.squareField}
                    onChange={e => this.setCartonData(e, 'h', index)}
                  />
                </div>
                <TextField
                  value={carton.cartonsQty}
                  label="Cartons Qty"
                  type="number"
                  className={classes.textField}
                  onChange={e => this.setCartonData(e, 'cartonsQty', index)}
                />
                <TextField
                  value={carton.cartonWeight}
                  label="Carton Weight(кг)"
                  type="number"
                  className={classes.textField}
                  onChange={e => this.setCartonData(e, 'cartonWeight', index)}
                />
              </div>
            );
          })}
        </div>
        <div className={classes.cartonsControl}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.setState({
                cartonsData: [
                  ...this.state.cartonsData,
                  { cartonWeight: '', cartonsQty: '', h: '', l: '', w: '' }
                ]
              });
            }}
          >
            Добавить
          </Button>
        </div>

        <TextField
          label="Total Weight"
          disabled
          value={
            parseFloat(
              (cartonsData.length > 0
                ? cartonsData
                    .map(
                      carton =>
                        parseFloat(carton.cartonWeight) *
                        parseFloat(carton.cartonsQty)
                    )
                    .reduce((prev, cur) => prev + cur)
                : 0
              ).toFixed(3)
            ) + ' кг'
          }
        />
        <TextField
          label="Total Volume"
          disabled
          value={
            parseFloat(
              (cartonsData.length > 0
                ? cartonsData
                    .map(
                      carton =>
                        ((parseFloat(carton.l) *
                          parseFloat(carton.w) *
                          parseFloat(carton.h)) /
                          1000000) *
                        carton.cartonsQty
                    )
                    .reduce((prev, cur) => prev + cur)
                : 0
              ).toFixed(3)
            ) + ' CBM'
          }
        />
        <TextField
          label="Volume Weight"
          disabled
          value={
            parseFloat(
              (cartonsData.length > 0
                ? cartonsData
                    .map(
                      carton =>
                        ((parseFloat(carton.l) *
                          parseFloat(carton.w) *
                          parseFloat(carton.h)) /
                          6000) *
                        carton.cartonsQty
                    )
                    .reduce((prev, cur) => prev + cur)
                : 0
              ).toFixed(3)
            ) + ' KG'
          }
        />
        <TextField
          label="Volume Weight Express"
          disabled
          value={
            parseFloat(
              (cartonsData.length > 0
                ? cartonsData
                    .map(
                      carton =>
                        ((parseFloat(carton.l) *
                          parseFloat(carton.w) *
                          parseFloat(carton.h)) /
                          5000) *
                        carton.cartonsQty
                    )
                    .reduce((prev, cur) => prev + cur)
                : 0
              ).toFixed(3)
            ) + ' KG'
          }
        />
        <TextField
          label="Invoice Amount(USD)"
          onChange={e => this.setValue(e, 'invoiceAmount')}
          value={invoiceAmount}
        />
        <TextField
          label="Ship To"
          onChange={e => this.setValue(e, 'shipTo')}
          value={shipTo}
          multiline
        />
        <div className={`${classes.cartonSize} ${classes.shippingMethod}`}>
          <Typography varinat="subtitle1">Shipping Method:</Typography>
          <Grid container justify="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={product.ae1}
                  color="primary"
                  onChange={this.handleChange('ae1')}
                />
              }
              label="Air Express (3-5 Days)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={ae2}
                  color="primary"
                  onChange={this.handleChange('ae2')}
                />
              }
              label="Air Express (5-7 Days)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={ra}
                  color="primary"
                  onChange={this.handleChange('ra')}
                />
              }
              label="Regular Air (10-12 Days)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={ms}
                  color="primary"
                  onChange={this.handleChange('ms')}
                />
              }
              label="Matson Sea (21-24 Days)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={rs}
                  color="primary"
                  onChange={this.handleChange('rs')}
                />
              }
              label="Regular Sea (30-35 Days)"
            />
          </Grid>
        </div>
        <div className={classes.cartonSize}>
          <Typography variant="subtitle1">Photos:</Typography>
          <Grid container alignItems="center">
            {photos.map(photo => {
              return (
                <div key={photo.name}>
                  <a href={photo.url}>
                    <img
                      src={photo.url}
                      className={classes.photo}
                      alt={photo.name}
                    />
                  </a>
                  <CloseIcon
                    className={classes.deletePhoto}
                    onClick={() => this.deletePhoto(photo)}
                  />
                </div>
              );
            })}
            <UploadPhoto onChange={this.addPhoto} />
          </Grid>
        </div>
        <div className={classes.emailContainer}>
          <FormControl className={classes.emailInput}>
            <InputLabel>Partner Emails:</InputLabel>
            <Select multiple value={partnerEmails} onChange={this.addEmail}>
              {allPartners.map(partner => (
                <MenuItem key={partner.name} value={partner.email}>
                  {`${partner.name} ${
                    partner.email ? ` | ${partner.email}` : ''
                  }`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Шапка письма"
            className={classes.emailInput}
            value={emailHeader}
            onChange={e => this.setValue(e, 'emailHeader')}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.sendEmail(false)}
          >
            Отправить на почту
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.sendEmail(true)}
          >
            Предосмотр
          </Button>
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(RoundView);
