import React, { FC, useState, ChangeEvent } from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import PhotoUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles({
  mainGrid: {
    width: 50,
    height: 50,
    background: '#f5f5f5',
    border: '1px solid #757575',
    borderRadius: '5px',
    cursor: 'pointer',
    color: '#757575',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 5px 0 5px'
  },

  uploadInput: {
    display: 'none'
  }
});

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement>, stopFunction: Function) => void;
}

const UploadPhoto: FC<Props> = props => {
  const { onChange } = props;
  const { mainGrid, uploadInput } = useStyles();

  const [isLoading, setLoading] = useState(false);

  return (
    <div>
      <input
        accept="image/*"
        className={uploadInput}
        multiple
        type="file"
        id="photo-uploader"
        onChange={e => {
          setLoading(true);
          onChange(e, () => setLoading(false));
        }}
      />
      <label htmlFor="photo-uploader" className={mainGrid}>
        {isLoading ? (
          <CircularProgress color="inherit" size={24} />
        ) : (
          <PhotoUploadIcon />
        )}
      </label>
    </div>
  );
};

export default UploadPhoto;
