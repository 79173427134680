import React, { Component, Fragment } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  withStyles,
  Grid,
  TextField,
  Button,
  CircularProgress
} from '@material-ui/core';

import { clone } from '../utils';

import API from '../API';

const styles = {
  wideInput: {
    width: '200px',
    margin: '10px'
  },

  disabledInput: {
    '& input': {
      color: 'black'
    }
  },

  addButton: {
    marginTop: '10px'
  },

  mainContainer: {
    padding: '10px',
    borderBottom: '1px solid'
  }
};

class AddRowForm extends Component {
  state = {
    round: '',
    transporter: '',
    type: '',
    formData: null,
    loading: false,
    partners: []
  };

  getList = () => {
    const { data } = this.props;

    const max = data.products.length;
    const result = [];

    for (let i = 0; i < max; i += 1) {
      result.push(<MenuItem key={i} value={i}>{`Рейс ${i + 1}`}</MenuItem>);
    }

    return result;
  };

  addOffer = () => {
    const { round } = this.state;
    const { data, getData } = this.props;

    //Get all data and set type
    let sendData = clone(this.state);
    sendData.transporter = sendData.transporter.email;
    delete sendData.partners;
    sendData = { ...sendData, ...sendData.formData };

    delete sendData.formData;
    delete sendData.round;
    delete sendData.loading;

    const db = API.firestore();

    this.setState({
      loading: true
    });

    //Connect to orders collection
    db.collection('orders')
      .where('id', '==', data.id)
      .get()
      .then(querySnap => {
        querySnap.forEach(doc => {
          //Add to order new offer and update document
          const order = doc.data();
          if (typeof order.products[round].offerList === 'undefined') {
            order.products[round].offerList = [sendData];
          } else {
            order.products[round].offerList.push(sendData);
          }

          db.collection('orders')
            .doc(doc.id)
            .update(order)
            .then(() => {
              this.setState({
                loading: false,
                round: '',
                transporter: '',
                type: '',
                formData: null
              });
              getData();
            });
        });
      });
  };

  setFormData = (e, key) => {
    const { formData } = this.state;
    const newFormData = clone(formData);

    newFormData[key] = e.target.value;

    this.setState({
      formData: newFormData
    });
  };

  getForm = type => {
    if (type === '') return null;

    if (type === 'DDU') {
      const { classes, data } = this.props;
      const { round, loading } = this.state;
      const {
        cbmCost,
        dutyRate,
        pickupCost,
        shippingMethod,
        volume
      } = this.state.formData;

      const product = data.products[round];
      const invoiceAmount = parseFloat(product.invoiceAmount);

      let freightCost = cbmCost * volume;
      let customs = (invoiceAmount / 100) * dutyRate;
      let totalCost = freightCost + customs;

      return (
        <Fragment>
          <FormControl className={classes.wideInput}>
            <InputLabel>Метод Перевозки</InputLabel>
            <Select
              value={shippingMethod}
              onChange={e => this.setFormData(e, 'shippingMethod')}
            >
              <MenuItem value="ae1">Air Express (3-5 Days)</MenuItem>
              <MenuItem value="ae2">Air Express (5-7 Days)</MenuItem>
              <MenuItem value="ra">Regular Air (10-12 Days)</MenuItem>
              <MenuItem value="ms">Matsor Sea (21-24 Days)</MenuItem>
              <MenuItem value="rs">Regular Sea (30-35 Days)</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Процент сборов"
            type="number"
            className={classes.wideInput}
            value={dutyRate}
            onChange={e => this.setFormData(e, 'dutyRate')}
          />
          <TextField
            label="Цена за CBM"
            type="number"
            className={classes.wideInput}
            value={cbmCost}
            onChange={e => this.setFormData(e, 'cbmCost')}
          />

          <TextField
            label="Стоимость PickUp"
            type="number"
            className={classes.wideInput}
            value={pickupCost}
            onChange={e => this.setFormData(e, 'pickupCost')}
          />
          <TextField
            label="Объем"
            type="number"
            className={classes.wideInput}
            onChange={e => this.setFormData(e, 'volume')}
            value={volume}
          />
          <TextField
            label="Стоимость Фрахта"
            type="number"
            className={`${classes.disabledInput} ${classes.wideInput}`}
            disabled
            value={freightCost}
          />
          <TextField
            label="Таможенный сбор"
            type="number"
            className={`${classes.disabledInput} ${classes.wideInput}`}
            disabled
            value={customs}
          />
          <TextField
            label="Итоговая сумма"
            type="number"
            className={`${classes.disabledInput} ${classes.wideInput}`}
            disabled
            value={totalCost.toFixed(2)}
          />
          {(loading && (
            <Grid container justify="center" alignItems="center">
              <CircularProgress />
            </Grid>
          )) || (
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.addButton}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={this.addOffer}
              >
                Add
              </Button>
            </Grid>
          )}
        </Fragment>
      );
    } else if (type === 'DDP') {
      const { classes } = this.props;
      const { loading } = this.state;
      const {
        priceKg,
        pickupCost,
        shippingMethod,
        qtyKg
      } = this.state.formData;
      let freightCost = priceKg * qtyKg;

      return (
        <Fragment>
          <FormControl className={classes.wideInput}>
            <InputLabel>Метод Перевозки</InputLabel>
            <Select
              value={shippingMethod}
              onChange={e => this.setFormData(e, 'shippingMethod')}
            >
              <MenuItem value="ae1">Air Express (3-5 Days)</MenuItem>
              <MenuItem value="ae2">Air Express (5-7 Days)</MenuItem>
              <MenuItem value="ra">Regular Air (10-12 Days)</MenuItem>
              <MenuItem value="ms">Matsor Sea (21-24 Days)</MenuItem>
              <MenuItem value="rs">Regular Sea (30-35 Days)</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Цена за КГ"
            type="number"
            value={priceKg}
            className={classes.wideInput}
            onChange={e => this.setFormData(e, 'priceKg')}
          />
          <TextField
            label="Стоимость PickUp"
            type="number"
            className={classes.wideInput}
            value={pickupCost}
            onChange={e => this.setFormData(e, 'pickupCost')}
          />
          <TextField
            label="Кол-во КГ"
            type="number"
            className={classes.wideInput}
            onChange={e => this.setFormData(e, 'qtyKg')}
            value={qtyKg}
          />
          <TextField
            label="Стоимость Фрахта"
            type="number"
            disabled
            className={`${classes.disabledInput} ${classes.wideInput}`}
            value={freightCost}
          />
          {(loading && (
            <Grid container justify="center" alignItems="center">
              <CircularProgress />
            </Grid>
          )) || (
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.addButton}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={this.addOffer}
              >
                Add
              </Button>
            </Grid>
          )}
        </Fragment>
      );
    }
  };

  setValue = (e, key) => {
    const value = e.target.value;
    this.setState({
      [key]: value
    });

    if (key === 'type') {
      const { round } = this.state;
      const { data } = this.props;

      const product = data.products[round];

      const DDUForm = {
        shippingMethod: '',
        cbmCost: '',
        pickupCost: '',
        dutyRate: '',
        volume: parseFloat(product.totalVolume)
      };

      const DDPForm = {
        shippingMethod: '',
        priceKg: '',
        qtyKg: parseFloat(product.totalWeight),
        pickupCost: ''
      };

      this.setState({
        formData: value === 'DDU' ? DDUForm : DDPForm
      });
    }
  };

  componentDidMount() {
    const db = API.firestore();

    db.collection('partners')
      .get()
      .then(querySnap => {
        this.setState({
          partners: querySnap.docs.map(doc => doc.data())
        });
      });
  }

  render() {
    const { round, transporter, type, partners } = this.state;
    const { classes } = this.props;

    return (
      <Grid container className={classes.mainContainer}>
        <FormControl className={classes.wideInput}>
          <InputLabel>Рейс</InputLabel>
          <Select value={round} onChange={e => this.setValue(e, 'round')}>
            {this.getList()}
          </Select>
        </FormControl>
        {round === '' ? null : (
          <FormControl className={classes.wideInput}>
            <InputLabel>Перевозчик</InputLabel>
            <Select
              value={transporter}
              onChange={e => this.setValue(e, 'transporter')}
            >
              {partners.map(partner => {
                return (
                  <MenuItem key={partner.email} value={partner}>
                    {`${partner.name} | ${partner.email}`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        {transporter === '' ? null : (
          <FormControl className={classes.wideInput}>
            <InputLabel>Тип</InputLabel>
            <Select value={type} onChange={e => this.setValue(e, 'type')}>
              <MenuItem value="DDP">DDP</MenuItem>
              <MenuItem value="DDU">DDU</MenuItem>
            </Select>
          </FormControl>
        )}
        {this.getForm(type)}
      </Grid>
    );
  }
}

export default withStyles(styles)(AddRowForm);
