import React, { Component } from 'react';
import { Button, TableCell, TableRow } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import API from '../API';
import { clone } from '../utils';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

class AdminRow extends Component {
  state = {
    focused: false,
    sendDate: null
  };

  componentDidMount() {
    const { rec } = this.props;

    this.setState({
      sendDate: rec.sendDate || null
    });
  }

  handleDateChange = date => {
    if (date) {
      const { rec } = this.props;
      const db = API.firestore();

      const newRec = clone(rec);
      newRec.sendDate = date.getTime();

      db.collection('orders')
        .where('id', '==', rec.id)
        .get()
        .then(querySnap => {
          querySnap.forEach(doc => {
            db.collection('orders')
              .doc(doc.id)
              .update(newRec)
              .then(() => {
                this.setState({ sendDate: date });
              });
          });
        });
    }
  };

  render() {
    const {
      rec,
      commonInfo,
      classes,
      formatDate,
      openDetails,
      removeOrder
    } = this.props;
    const { sendDate } = this.state;

    const transName = rec.products
      .map(product => {
        const doneOffer =
          product.offerList && product.offerList.filter(offer => offer.done)[0];
        const allPartners = product.allPartners;

        const partner =
          allPartners &&
          allPartners.filter(
            partner => partner.email === (doneOffer && doneOffer.transporter)
          )[0];

        return partner && partner.name;
      })
      .filter(partner => partner !== undefined)
      .join();

    const ourPrice = rec.offerHistory
      ? rec.offerHistory
          .map(offer => {
            const sum = offer.pdf.sum;

            return parseFloat(sum) || 0;
          })
          .reduce((acc, cur) => {
            return acc + cur;
          }, 0)
      : 0;

    const transPriceSums = rec.products.map(product => {
      const doneOffers = product.offerList
        ? product.offerList.filter(offer => offer.done)
        : [];
      const doneOffer = doneOffers.length > 0 ? doneOffers[0] : null;

      return doneOffer === null ? 0 : parseFloat(doneOffer.userData.cost);
    });

    const doneOfferCheck =
      rec.products
        .map(
          product =>
            product.offerList &&
            product.offerList.filter(offer => offer.done)[0]
        )
        .filter(offer => offer !== undefined).length === 0;

    const transPrice =
      transPriceSums.length > 1
        ? transPriceSums.reduce((acc, cur) => acc + cur)
        : transPriceSums[0];

    const paid =
      rec.offerHistory &&
      rec.offerHistory
        .map(offer => {
          const due = offer.balance.due;
          const sum = offer.pdf.sum;

          return sum - due === 0;
        })
        .every(paid => paid);

    return (
      <TableRow key={rec.id}>
        <TableCell>{rec.id}</TableCell>
        <TableCell>{rec.clientName}</TableCell>
        <TableCell>
          {formatDate(new Date(rec.createDate.seconds * 1000))}
        </TableCell>
        <TableCell>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              value={sendDate}
              onChange={this.handleDateChange}
            />
          </MuiPickersUtilsProvider>
        </TableCell>
        <TableCell style={{ whiteSpace: 'pre' }}>{commonInfo}</TableCell>
        <TableCell>{transName}</TableCell>
        <TableCell>${transPrice && transPrice.toFixed(2)}</TableCell>
        <TableCell>${ourPrice}</TableCell>
        <TableCell>${(ourPrice - transPrice).toFixed(2)}</TableCell>
        <TableCell>
          {paid && !doneOfferCheck ? 'Оплачено' : 'Неоплачено'}
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            onClick={() => openDetails(rec.id)}
          >
            Детали
          </Button>
        </TableCell>
        <TableCell>
          <div
            onClick={() => removeOrder(rec.id)}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </div>
        </TableCell>
      </TableRow>
    );
  }
}

export default AdminRow;
