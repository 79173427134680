import React, { Component, Fragment } from 'react';
import { Button, TableCell, TableRow, withStyles } from '@material-ui/core';

import SelectInput from './SelectInput';
import API from '../API';
import { clone } from '../utils';
import TextField from '@material-ui/core/TextField';

const styles = {
  selectFile: {
    width: '100px'
  },

  sumsField: {
    display: 'flex',
    height: '100%',
    '& > div': {
      alignSelf: 'flex-end'
    },
    '& > button': {
      height: '34px',
      alignSelf: 'center'
    },
    '& .MuiInput-input': {
      width: '75px'
    }
  },

  sumsCell: {
    paddingBottom: '6px'
  },

  attach: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  detailsCell: {
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      whiteSpace: 'nowrap'
    }
  }
};

class BillRow extends Component {
  state = {
    status: '',
    sums: '',
    files: [],
    attSums: ''
  };

  setStatus = status => this.setState({ status });

  getFormatData = (data, currency) => {
    return data ? `${currency || ''}${data}` : 'Нет данных';
  };

  getDetails = (offer, totalVolume, invoiceAmount) => {
    const { type } = offer;

    if (type === 'DDP') {
      const { pickupCost, priceKg, qtyKg } = offer;

      return `
    <span>
    <b>Цена КГ:</b> ${this.getFormatData(priceKg, '$')},
    <b>Кол-во КГ:</b> ${this.getFormatData(qtyKg)},
    <b>Фрахт:</b> ${this.getFormatData(
      (parseFloat(priceKg) * parseFloat(qtyKg)).toFixed(2),
      '$'
    )},</span>
    <span>
    <b>PickUp:</b> ${this.getFormatData(pickupCost, '$')}</span>
    `;
    } else if (type === 'DDU') {
      const { cbmCost, dutyRate, pickupCost, volume } = offer;
      let freightCost = cbmCost * totalVolume;
      let customs = (invoiceAmount / 100) * dutyRate;

      return `
    <span>
    <b>Цена CBM:</b> ${this.getFormatData(cbmCost, '$')},
    <b>Объем:</b> ${this.getFormatData(volume || totalVolume)},
    <b>Фрахт:</b> ${this.getFormatData(
      (parseFloat(totalVolume) * cbmCost).toFixed(2),
      '$'
    )},</span>
    <span>
    <b>% сборов:</b> ${this.getFormatData(dutyRate)}%,
    <b>Сбор:</b> ${this.getFormatData(
      ((parseFloat(invoiceAmount) / 100) * dutyRate).toFixed(2),
      '$'
    )},
    <b>Итог. сумма:</b> ${parseFloat(
      freightCost + customs + parseFloat(pickupCost)
    ).toFixed(2)},</span><span>
    <b>PickUp:</b> ${this.getFormatData(pickupCost, '$')}</span>
    `;
    }
  };

  addFile = event => {
    const { data, index, offerIndex } = this.props;
    const { files, attSums } = this.state;

    Array.prototype.forEach.call(event.target.files, file => {
      const storageRef = API.storage().ref(file.name);
      storageRef.put(file).then(res => {
        res.ref.getMetadata().then(metadata => {
          const name = metadata.name;

          res.ref.getDownloadURL().then(url => {
            const db = API.firestore();
            const newData = clone(data);
            if (
              typeof newData.products[index].offerList[offerIndex].files ===
              'undefined'
            ) {
              newData.products[index].offerList[offerIndex].files = [];
            }

            newData.products[index].offerList[offerIndex].files.push({
              name,
              url
            });

            db.collection('orders')
              .where('id', '==', data.id)
              .get()
              .then(querySnap => {
                querySnap.forEach(doc => {
                  db.collection('orders')
                    .doc(doc.id)
                    .update(newData)
                    .then(() => {
                      this.setState({
                        files: [...files, { name, url }]
                      });
                    });
                });
              });
          });
        });
      });
    });
  };

  saveSums = () => {
    const { data, index, offerIndex } = this.props;
    const { sums } = this.state;

    const newData = clone(data);

    const db = API.firestore();

    newData.products[index].offerList[offerIndex].userData.cost = sums;

    db.collection('orders')
      .where('id', '==', data.id)
      .get()
      .then(querySnap => {
        querySnap.forEach(doc => {
          db.collection('orders')
            .doc(doc.id)
            .update(newData)
            .then(() => {});
        });
      });
  };

  componentDidMount() {
    const { submitedOffer } = this.props;

    this.setState({
      status: submitedOffer.status || 'Не оплачено',
      files: submitedOffer.files || [],
      sums: submitedOffer.userData.cost || ''
    });
  }

  handleChange = val => {
    const { data, index, offerIndex } = this.props;
    const db = API.firestore();

    const newData = clone(data);
    newData.products[index].offerList[offerIndex].status = val;

    db.collection('orders')
      .where('id', '==', data.id)
      .get()
      .then(querySnap => {
        querySnap.forEach(doc => {
          db.collection('orders')
            .doc(doc.id)
            .update(newData)
            .then(() => {
              this.setState({
                status: val
              });
            });
        });
      });
  };

  getUserData = () => {
    const { userData } = this.props.submitedOffer;
    const { qty, ourPrice, ourPickUp, type, cost } = userData;
    const sum = parseFloat(qty) * parseFloat(ourPrice);

    return `
      <span><b>Стоимость:</b> ${this.getFormatData(
        parseFloat(cost).toFixed(2),
        '$'
      )};
      <b>Тип:</b> ${this.getFormatData(type)};
      <b>Наша цена за ед.:</b> ${this.getFormatData(ourPrice, '$')};</span>
      <span><b>Кол-во ед.:</b> ${this.getFormatData(qty)};
      <b>Сумма:</b> ${this.getFormatData(sum, '$')};
      <b>Наш PickUp:</b> ${this.getFormatData(ourPickUp, '$')};</span>
      <span><b>Прибыль:</b> ${this.getFormatData(
        (sum - parseFloat(cost) + parseFloat(ourPickUp)).toFixed(2),
        '$'
      )}</span>
    `;
  };

  render() {
    const { index, submitedOffer, classes, data } = this.props;
    const { status, files, sums } = this.state;

    const invoiceAmount = data.products[index].invoiceAmount;
    const totalVolume = data.products[index].totalVolume;

    const partners = data.products[index].allPartners.filter(partner => {
      return partner.email === submitedOffer.transporter;
    });

    return (
      <Fragment>
        <TableRow>
          <TableCell>{partners[0] && partners[0].name}</TableCell>
          <TableCell>
            <SelectInput
              value={status}
              values={[
                'Не оплачено',
                'PayPal',
                'Payoneer',
                'Transferwise',
                'Bank',
                'Cash'
              ]}
              label=""
              onChange={e => this.handleChange(e.target.value)}
            />
          </TableCell>
          <TableCell>
            <div className={classes.attach}>
              <TextField
                className={classes.selectFile}
                label="Сумма/"
                onChange={e => {
                  console.log(
                    e.target.value.split('/').map(sum => parseFloat(sum))
                  );

                  this.setState({
                    attSums: e.target.value,
                    sums: e.target.value
                      .split('/')
                      .map(sum => parseFloat(sum))
                      .filter(num => !isNaN(num))
                      .reduce((prev, cur) => prev + cur, 0)
                  });
                }}
                value={this.state.attSums}
              />
              <input
                hidden
                id={`fileUpload-${index}`}
                multiple
                type="file"
                onChange={this.addFile}
              />
              <label htmlFor={`fileUpload-${index}`}>
                <Button variant="contained" component="span">
                  Attach...
                </Button>
              </label>
            </div>
          </TableCell>
          <TableCell>
            <SelectInput
              label="Files"
              value=""
              values={files}
              file={true}
              classes={classes.selectFile}
            />
          </TableCell>
          <TableCell className={classes.sumsCell}>
            <div className={classes.sumsField}>
              <TextField
                value={this.state.sums}
                onChange={e => this.setState({ sums: e.target.value })}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={this.saveSums}
              >
                Сохр.
              </Button>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <div
              className={classes.detailsCell}
              dangerouslySetInnerHTML={{
                __html: this.getDetails(
                  submitedOffer,
                  totalVolume,
                  invoiceAmount
                )
              }}
            />
          </TableCell>
          <TableCell>
            <div
              className={classes.detailsCell}
              dangerouslySetInnerHTML={{ __html: this.getUserData() }}
            />
          </TableCell>
          <TableCell />
          <TableCell />
          <TableCell />
        </TableRow>
      </Fragment>
    );
  }
}

export default withStyles(styles)(BillRow);
