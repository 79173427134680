import React, { Component } from 'react';
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead
} from '@material-ui/core';
import { format } from 'date-fns';

import API from '../API';

class OrdersData extends Component {
  state = {
    orders: []
  };

  getOrders = () => {
    const { uid, partner, userData } = this.props;

    if (!partner) {
      const db = API.firestore();

      db.collection('orders')
        .where('uid', '==', uid)
        .get()
        .then(querySnap => {
          querySnap.forEach(this.setOrdersData);
        });
    } else {
      this.setState({
        orders: userData.offerHistory
      });
    }
  };

  setOrdersData = doc => {
    const { orders } = this.state;
    const { partner } = this.props;

    if (!partner) {
      const data = doc.data();
      const partnerNames = data.products
        .map(product => {
          const doneOffer = product.offerList
            ? product.offerList.filter(offer => offer.done)[0]
            : undefined;
          const doneOfferTransporter = doneOffer && doneOffer.transporter;

          const partner = product.allPartners
            ? product.allPartners.filter(
                partner => partner.email === doneOfferTransporter
              )[0]
            : undefined;
          return partner ? partner.name : '';
        })
        .join();
      const profit = data.products
        .map(product => {
          const doneOffers = product.offerList
            ? product.offerList.filter(offer => offer.done)[0]
            : undefined;

          if (doneOffers === undefined) {
            return 0;
          }

          const { ourPrice, qty } = doneOffers.userData;

          if (typeof doneOffers !== 'undefined') {
            return parseFloat(ourPrice) * parseFloat(qty);
          }

          return 0;
        })
        .reduce((acc, cur) => acc + cur, 0);

      const orderData = {
        partnerNames,
        createDate: format(data.createDate.seconds * 1000, 'dd/MM/yyyy'),
        profit,
        status: data.status
      };

      this.setState({
        orders: [...orders, orderData]
      });
    }
  };

  componentDidMount() {
    this.getOrders();
  }

  getStatus = status => {
    const variants = {
      new: 'Новый',
      active: 'Активный',
      archived: 'Завершенный'
    };

    return variants[status];
  };

  render() {
    const { partner } = this.props;
    const { orders } = this.state;

    const partnerHeaders = [
      'Имя Клиента',
      'Дата Заказа',
      'Прибыль',
      'Статус Оплаты'
    ];
    const clientHeaders = [
      'Название Партнера',
      'Дата Заказа',
      'Прибыль',
      'Статус Оплаты'
    ];
    const headers = partner ? partnerHeaders : clientHeaders;

    return (
      <Table>
        <TableHead>
          <TableRow>
            {headers.map(header => {
              return <TableCell key={header}>{header}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order, index) => {
            if (!partner) {
              return (
                <TableRow key={index}>
                  <TableCell>{order.partnerNames}</TableCell>
                  <TableCell>{order.createDate}</TableCell>
                  <TableCell>${order.profit}</TableCell>
                  <TableCell>{this.getStatus(order.status)}</TableCell>
                </TableRow>
              );
            } else {
              return (
                <TableRow key={index}>
                  <TableCell>{order.clientName}</TableCell>
                  <TableCell>{order.createdDate}</TableCell>
                  <TableCell>${order.profit}</TableCell>
                  <TableCell>{order.status}</TableCell>
                </TableRow>
              );
            }
          })}
        </TableBody>
      </Table>
    );
  }
}

export default OrdersData;
