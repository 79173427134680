import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

const SelectInput = props => (
  <FormControl className={props.classes}>
    <InputLabel>{props.label}</InputLabel>
    <Select value={props.value} onChange={props.onChange}>
      {props.values.map(value => (
        <MenuItem key={props.file ? value.name : value} value={props.file ? value.url : value}>
          {props.file ?
            <a href={value.url} download>{value.name}</a>:
            value}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default SelectInput;
