import React, { Component } from 'react';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from '@material-ui/core';

import API from '../API';
import BillHistoryRow from './BillHistoryRow';
import { clone } from '../utils';

const styles = {
  margin: {
    margin: '10px'
  },

  stickyHead: {
    '& th': {
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      zIndex: 10
    }
  }
};

class BillHistory extends Component {
  state = {
    date: null
  };

  componentDidMount() {
    const { data } = this.props;

    this.setState({
      data
    });
  }

  componentDidUpdate() {
    if (this.props.updateHistory) {
      const { data } = this.state;
      const { updHistory } = this.props;
      const db = API.firestore();

      db.collection('orders')
        .where('id', '==', data.id)
        .get()
        .then(querySnap => {
          querySnap.forEach(doc => {
            this.setState({
              data: doc.data()
            });
            updHistory(false);
          });
        });
    }
  }

  saveTable = () => {
    const { data } = this.state;
    const newData = clone(data);
    const { offerHistory } = newData;

    const max = offerHistory && offerHistory.length;
    for (let i = 0; i < max; i += 1) {
      const offerRow = this[`ref-${i}`].current;
      if (offerRow !== null) {
        const newState = offerRow.state;
        delete newState.expanded;
        newData.offerHistory[i] = newState;
      }
    }

    const db = API.firestore();

    db.collection('orders')
      .where('id', '==', newData.id)
      .get()
      .then(querySnap => {
        querySnap.forEach(doc => {
          db.collection('orders')
            .doc(doc.id)
            .update(newData);
        });
      });
  };

  render() {
    const { data } = this.state;
    const { classes } = this.props;

    return (
      <Grid container justify="center" alignItems="center" direction="column">
        <Typography variant="h5">История Платежей</Typography>
        <Grid justify="flex-start" container>
          <Button
            variant="contained"
            color="primary"
            className={classes.margin}
            onClick={this.saveTable}
          >
            Сохр.
          </Button>
        </Grid>
        <Table>
          <TableHead className={classes.stickyHead}>
            <TableRow>
              {[
                '',
                'Номер счета',
                'Счет выписан',
                'Оплатить до',
                'Сумма',
                'Заказчик',
                'Баланс платежей',
                'PDF'
              ].map((category, index) => {
                return (
                  <TableCell key={`${category}-${index}`}>{category}</TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.offerHistory &&
              data.offerHistory.map((history, index) => {
                this[`ref-${index}`] = React.createRef();
                return (
                  <BillHistoryRow
                    key={index}
                    ref={this[`ref-${index}`]}
                    history={history}
                  />
                );
              })}
          </TableBody>
        </Table>
      </Grid>
    );
  }
}

export default withStyles(styles)(BillHistory);
