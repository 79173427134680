import React, { Component } from 'react';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from '@material-ui/core';
import Reorder from '@material-ui/icons/Reorder';
import AdminRow from '../components/AdminRow';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import ClientAddForm from '../components/ClientAddForm';
import PartnerAddForm from '../components/PartnerAddForm';

import { Link } from 'react-router-dom';

import API from '../API';

import Sidebar from '../components/Sidebar';
import DetailModal from '../components/DetailModal';
import UserModal from '../components/UserModal';

const styles = {
  mainContainer: {
    marginTop: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '90vw',
    '& > .MuiPaper-root': {
      overflowX: 'auto'
    }
  },

  table: {},

  category: {
    display: 'flex',
    alignItems: 'center',
    '& h6': {
      marginTop: '5px'
    }
  },

  closeIcon: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    background: 'red',
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
    alignItems: 'center',
    cursor: 'pointer'
  },

  sidebarButton: {
    fontSize: '30px',
    margin: '5px 0 0 5px',
    cursor: 'pointer'
  }
};

const CollisionLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/addProduct" {...props} />
));

class AdminPage extends Component {
  state = {
    sidebarOpen: false,
    dataType: 'new',
    data: [],
    updateData: true,
    rowsPerPage: 10,
    page: 1,
    openDetails: null,
    clientData: null,
    partnerData: null,
    modalOpen: false
  };

  toggleSidebar = () => {
    const { sidebarOpen } = this.state;

    this.setState({
      sidebarOpen: !sidebarOpen
    });
  };

  selectDataType = index => {
    const categoryVariant = [
      'new',
      'active',
      'done',
      'archived',
      'clients',
      'partners'
    ];

    this.setState({
      dataType: categoryVariant[index],
      sidebarOpen: false,
      updateData: true,
      data: []
    });

    this.genData();
  };

  formatDate(date) {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${day}/${monthIndex + 1}/${year}`;
  }

  genData = () => {
    const { dataType, data, updateData, rowsPerPage, page } = this.state;
    const { classes } = this.props;
    const db = API.firestore();

    if (dataType !== 'clients' && dataType !== 'partners') {
      db.collection('orders')
        .where('status', '==', dataType)
        .limit(rowsPerPage * page)
        .get()
        .then(querySnap => {
          if (updateData) {
            this.setState({
              data: querySnap.docs
                .map(doc => doc.data())
                .sort((prev, cur) => {
                  return cur.createDate.seconds - prev.createDate.seconds;
                }),
              updateData: false
            });
          }
        });

      return data.map((rec, index) => {
        const add = (prev, cur) => prev + cur;

        const qtyArr = rec.products.map(product => {
          return product.cartonsData.map(carton => parseInt(carton.cartonsQty));
        });

        function getMaxOfArray(numArray) {
          return Math.max.apply(null, numArray);
        }

        let commonInfo = qtyArr.map((round, index) => {
          const currentRec = rec.products[index];
          return `${round.reduce(add, 0)} ${' - ' +
            getMaxOfArray(
              [
                currentRec.totalWeight,
                currentRec.volumeWeight,
                currentRec.volumeWeightExpress
              ].map(str => parseFloat(str))
            ).toFixed(2) +
            ' кг'} - ${currentRec.totalVolume} CBM\n`;
        });

        return (
          <AdminRow
            key={index}
            commonInfo={commonInfo}
            rec={rec}
            classes={classes}
            formatDate={this.formatDate}
            openDetails={this.openDetails}
            removeOrder={this.removeOrder}
          />
        );
      });
    } else if (dataType === 'clients') {
      db.collection('userDetails')
        .limit(rowsPerPage * page)
        .get()
        .then(querySnap => {
          if (updateData) {
            this.setState({
              data: querySnap.docs.map(doc => ({
                ...doc.data(),
                docId: doc.id
              })),
              updateData: false
            });
          }
        });

      return data.map(user => {
        const {
          firstName,
          lastName,
          wechat,
          phone,
          companyName,
          email,
          docId
        } = user;

        return (
          <TableRow key={docId}>
            <TableCell></TableCell>
            <TableCell>{`${firstName} ${lastName}`}</TableCell>
            <TableCell>{companyName}</TableCell>
            <TableCell>{email}</TableCell>
            <TableCell>Skype</TableCell>
            <TableCell>{wechat}</TableCell>
            <TableCell>{phone}</TableCell>
            <TableCell>Country</TableCell>
            <TableCell>Add Date</TableCell>
            <TableCell>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  this.setState({ modalOpen: true, clientData: user })
                }
              >
                Детали
              </Button>
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const del = window.confirm('Вы уверены?');
                  if (del === true) {
                    const db = API.firestore();
                    db.collection('userDetails')
                      .doc(docId)
                      .delete()
                      .then(() => {
                        this.setState({
                          updateData: true
                        });
                      });
                  }
                }}
              >
                Удалить
              </Button>
            </TableCell>
          </TableRow>
        );
      });
    } else if (dataType === 'partners') {
      db.collection('partners')
        .limit(rowsPerPage * page)
        .get()
        .then(querySnap => {
          if (updateData) {
            this.setState({
              data: querySnap.docs.map(doc => ({
                ...doc.data(),
                docId: doc.id
              })),
              updateData: false
            });
          }
        });

      return data.map((partner, index) => {
        const { name, email, companyName, phone, site } = partner;
        return (
          <TableRow key={`${name}-${index}`}>
            <TableCell>{companyName || ''}</TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>{email}</TableCell>
            <TableCell>{phone || ''}</TableCell>
            <TableCell>{site}</TableCell>
            <TableCell>
              <Button
                onClick={() =>
                  this.setState({
                    modalOpen: true,
                    partnerData: partner
                  })
                }
                variant="contained"
                color="primary"
              >
                Детали
              </Button>
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const del = window.confirm('Вы уверены?');
                  if (del === true) {
                    const db = API.firestore();
                    db.collection('partners')
                      .where('email', '==', email)
                      .get()
                      .then(qs => {
                        qs.forEach(doc => {
                          db.collection('partners')
                            .doc(doc.id)
                            .delete()
                            .then(() => {
                              this.setState({
                                updateData: true
                              });
                            });
                        });
                      });
                  }
                }}
              >
                Удалить
              </Button>
            </TableCell>
          </TableRow>
        );
      });
    }
  };

  addMore = e => {
    const { page } = this.state;
    this.setState({
      page: page + 1,
      updateData: true
    });
  };

  openDetails = id => {
    this.setState({
      openDetails: id
    });
  };

  removeOrder = id => {
    const db = API.firestore();

    db.collection('orders')
      .where('id', '==', id)
      .get()
      .then(querySnap => {
        querySnap.forEach(doc => {
          db.collection('orders')
            .doc(doc.id)
            .update({ status: 'archived' })
            .then(() => {
              this.setState({
                updateData: true
              });
            });
        });
      });
  };

  handleClose = () => {
    this.setState({
      openDetails: null,
      modalOpen: false,
      clientData: null,
      partnerData: null
    });
  };

  genHeaders = () => {
    const { dataType } = this.state;

    const variants = {
      new: [
        '№ Заказа',
        'Имя Клиента',
        'Дата Заказа',
        'Дата Отправки',
        'Cartons Qty - Charge Weight - Volume',
        'Название Перевозчика',
        'Цена Перевозчика',
        'Наша Цена',
        'Прибыль',
        // 'Статус оплаты',
        '',
        '',
        ''
      ],
      clients: [
        '№ Клиента',
        'Имя Клиента',
        'Название Комп. Клиента',
        'Email',
        'Skype',
        'Wechat',
        'Контакт. тел.',
        'Страна',
        'Дата добавления',
        '',
        ''
      ],
      partners: [
        'Название Комп. Пер.',
        'Имя контакт. лиц.',
        'Email контакт. лиц.',
        'Контакт. тел.',
        'Сайт',
        '',
        ''
      ]
    };

    variants['active'] = variants.new;
    variants['done'] = variants.new;
    variants['archived'] = variants.new;

    return variants[dataType].map((column, index) => {
      return <TableCell key={`${column}-${index}`}>{column}</TableCell>;
    });
  };

  getTitle = () => {
    const { dataType } = this.state;

    const variants = {
      new: 'Новые Запросы',
      active: 'Активные Запросы',
      done: 'Завершенные Запросы',
      archived: 'Архивированные запросы',
      clients: 'Клиенты',
      partners: 'Партнеры'
    };

    return variants[dataType];
  };

  render() {
    const {
      sidebarOpen,
      dataType,
      openDetails,
      modalOpen,
      clientData,
      partnerData
    } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <Sidebar
          open={sidebarOpen}
          toggleSidebar={this.toggleSidebar}
          selectDataType={this.selectDataType}
          category={dataType}
          changeCategory={this.selectDataType}
        />
        {typeof openDetails === 'string' ? (
          <DetailModal
            onClose={this.handleClose}
            modalOpen={true}
            id={openDetails}
          />
        ) : null}
        <UserModal
          open={modalOpen}
          updateData={() => this.setState({ updateData: true })}
          userData={clientData === null ? partnerData : clientData}
          partner={clientData === null}
          onClose={() => this.setState({ modalOpen: false })}
        />
        <div className={classes.category}>
          <Reorder
            onClick={() => this.setState({ sidebarOpen: !sidebarOpen })}
            className={classes.sidebarButton}
          />
          <Typography variant="subtitle1">{this.getTitle()}</Typography>
        </div>

        <Grid container justify="center" className={classes.mainContainer}>
          <Paper>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>{this.genHeaders()}</TableRow>
              </TableHead>
              <TableBody>
                {dataType === 'clients' ? (
                  <ClientAddForm
                    isTable={true}
                    genData={() => this.setState({ updateData: true })}
                  />
                ) : null}
                {dataType === 'partners' ? (
                  <PartnerAddForm
                    genData={() => this.setState({ updateData: true })}
                  />
                ) : null}
                {this.genData()}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid container justify="center">
          <Button onClick={this.addMore}>Показать Больше</Button>
          {dataType !== 'clients' && dataType !== 'partners' ? (
            <Button component={CollisionLink}>Добавить Продукт</Button>
          ) : null}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(AdminPage);
