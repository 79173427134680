import React from 'react';
import { Grid, CircularProgress, makeStyles } from '@material-ui/core';

const useStyle = makeStyles(() => ({
  root: {
    height: '100vh',
    width: '100wh'
  }
}));

const LoadingPage: React.FC = () => {
  const classes = useStyle();

  return (
    <Grid container justify="center" className={classes.root} alignItems="center">
      <CircularProgress size={70} />
    </Grid>
  );
};

export default LoadingPage;
