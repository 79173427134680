import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import PrivateRoute from './components/PrivateRoute';

import API from './API';

import AuthPage from './pages/AuthPage';
import Dashboard from './pages/Dashboard';
import SuccessPage from './pages/SuccessPage';
import Header from './components/Header';
import MobileHeader from './components/MobileHeader';
import UserPage from './pages/UserPage';

class App extends Component {
  componentDidMount() {
    API.auth().onAuthStateChanged(user => {
      if (user) {
        localStorage.setItem('BLH.expectSignIn', '1');
        this.forceUpdate();
      } else {
        localStorage.removeItem('BLH.expectSignIn');
        this.forceUpdate();
        // Implement logic to trigger the login dialog here or redirect to sign-in page.
        // e.g. showDialog()
      }
    });
  }

  render() {
    return (
      <Router>
        <Header />
        <MobileHeader />
        <Switch>
          <Route exact path="/(login|register)" component={AuthPage} />
          <PrivateRoute exact path="/success" component={SuccessPage} />
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute component={UserPage} path="/addProduct" />
        </Switch>
      </Router>
    );
  }
}

export default App;
