import React, { Component } from 'react';
import { Grid, TextField, withStyles, Button } from '@material-ui/core';

import SelectInput from './SelectInput';
import API from '../API';
import { clone } from '../utils';

const styles = {
  mainGrid: {
    width: '100%',
    height: '100%'
  },

  textArea: {
    width: '80%'
  },

  filesGrid: {
    height: '50px'
  },

  margin: {
    margin: '10px'
  },

  width200: {
    width: '200px'
  }
};

class InfoTab extends Component {
  state = {
    notes: '',
    files: []
  };

  addFile = event => {
    const { files } = this.state;
    const { data } = this.props;

    Array.prototype.forEach.call(event.target.files, file => {
      const storageRef = API.storage().ref(file.name);
      storageRef.put(file).then(res => {
        res.ref.getMetadata().then(metadata => {
          const name = metadata.name;

          res.ref.getDownloadURL().then(url => {
            const db = API.firestore();
            const newData = clone(data);

            if (typeof newData.info === 'undefined') {
              newData.info = {
                files: [{ name, url }]
              };
            } else {
              newData.info.files.push({ name, url });
            }

            db.collection('orders')
              .where('id', '==', data.id)
              .get()
              .then(querySnap => {
                querySnap.forEach(doc => {
                  db.collection('orders')
                    .doc(doc.id)
                    .update(newData)
                    .then(() => {
                      this.setState({
                        files: [...files, { name, url }]
                      });
                    });
                });
              });
          });
        });
      });
    });
  };

  saveNotes = () => {
    const { data } = this.props;
    const { notes } = this.state;

    const newData = clone(data);

    if (typeof newData.info === 'undefined') {
      newData.info = {
        notes,
        files: []
      };
    } else {
      newData.info.notes = notes;
    }

    const db = API.firestore();

    db.collection('orders')
      .where('id', '==', data.id)
      .get()
      .then(querySnap => {
        querySnap.forEach(doc => {
          db.collection('orders')
            .doc(doc.id)
            .update(newData)
            .then(() => {});
        });
      });
  };

  componentDidMount() {
    const { id } = this.props;
    const db = API.firestore();

    db.collection('orders')
      .where('id', '==', id)
      .get()
      .then(querySnap => {
        querySnap.forEach(doc => {
          const data = doc.data();

          this.setState({
            notes: (data.info && data.info.notes) || '',
            files: (data.info && data.info.files) || []
          });
        });
      });
  }

  render() {
    const { classes } = this.props;
    const { notes, files } = this.state;

    return (
      <Grid container justify="center" className={classes.mainGrid}>
        <TextField
          label="Заметки"
          className={classes.textArea}
          multiline
          rows={10}
          onChange={e => this.setState({ notes: e.target.value })}
          value={notes}
        />
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.filesGrid}
        >
          <Button
            variant="contained"
            className={classes.margin}
            onClick={this.saveNotes}
            color="primary"
            component="span"
          >
            Сохранить
          </Button>
          <input
            hidden
            id="fileUpload"
            multiple
            type="file"
            onChange={this.addFile}
          />
          <label htmlFor="fileUpload">
            <Button variant="contained" color="primary" component="span">
              Добавить файлы
            </Button>
          </label>
          <SelectInput
            value=""
            values={files}
            file={true}
            classes={`${classes.margin} ${classes.width200}`}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(InfoTab);
