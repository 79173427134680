import React, { FC, ComponentType } from 'react';
import { Redirect, Route } from 'react-router-dom';

import API from '../API';

import LoadingPage from '../pages/LoadingPage';

interface Props {
  exact?: boolean;
  component: ComponentType;
  path: string;
}

const PrivateRoute: FC<Props> = props => {
  return API.auth().currentUser !== null ? (
    <Route {...props} />
  ) : localStorage.getItem('BLH.expectSignIn') ? (
    <LoadingPage />
  ) : (
    <Redirect from={props.path} to="/login" />
  );
};

export default PrivateRoute;
