import React, { Component } from 'react';
import {
  TextField,
  Button,
  Grid,
  Link,
  CircularProgress,
  withStyles
} from '@material-ui/core';
import { compose } from 'redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';

import Notification from '../components/Notifications';

import API from '../API';

const RegisterLink = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} {...props} to="/register" />
));

const styles = {
  registerLink: {
    marginTop: '10px'
  }
};

class LoginForm extends Component {
  state = {
    email: '',
    pass: '',
    errorMsg: '',
    loading: false
  };

  handleSubmit = () => {
    const { email, pass } = this.state;
    const { history } = this.props;

    if (!email || !pass) {
      this.setState({
        errorMsg: 'Empty email or password field.'
      });
      return;
    }

    this.setState({
      loading: true
    });

    API.auth()
      .signInWithEmailAndPassword(email, pass)
      .then(() => {
        this.setState({
          loading: false
        });
        history.push('/');
      })
      .catch(err => {
        this.setState({
          loading: false,
          errorMsg: err.message
        });
      });
  };

  render() {
    const { email, pass, errorMsg, loading } = this.state;
    const { classes } = this.props;

    return (
      <form noValidate>
        {errorMsg ? (
          <Notification
            variant="error"
            message={errorMsg}
            onClose={() => this.setState({ errorMsg: '' })}
          />
        ) : null}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address / Электронная почта"
          name="email"
          autoComplete="email"
          value={email}
          onChange={event => this.setState({ email: event.target.value })}
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password / Пароль"
          type="password"
          id="password"
          value={pass}
          onChange={event => this.setState({ pass: event.target.value })}
          autoComplete="current-password"
        />
        {loading ? (
          <Grid container alignItems="center" justify="center">
            <CircularProgress size={36} />
          </Grid>
        ) : (
          <Button
            onClick={this.handleSubmit}
            fullWidth
            variant="contained"
            color="primary"
          >
            Sign In
          </Button>
        )}
        <Grid container>
          <Grid className={classes.registerLink} item>
            <Link component={RegisterLink} variant="body2">
              Don't have an account? Sign Up
            </Link>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(LoginForm);
