import React, { Component, ReactElement } from 'react';

import UserPage from './UserPage';
import AdminPage from './AdminPage';

import API from '../API';
import LoadingPage from './LoadingPage';

interface State {
  role: 'user' | 'admin' | 'loading';
}

interface Variants {
  admin: ReactElement;
  user: ReactElement;
  loading: ReactElement;
}

class Dashboard extends Component<{}> {
  state: State = {
    role: 'loading'
  };

  componentDidMount() {
    const user = API.auth().currentUser;

    if (user) {
      const db = API.firestore();
      db.collection('admins')
        .where('uid', '==', user.uid)
        .get()
        .then(docSnap => {
          if (!docSnap.empty) {
            this.setState({
              role: 'admin'
            });
          } else if (docSnap.empty) {
            this.setState({
              role: 'user'
            });
          }
        });
    }
  }

  render() {
    const { role } = this.state;

    const variants: Variants = {
      admin: <AdminPage />,
      user: <UserPage />,
      loading: <LoadingPage />
    };

    return variants[role];
  }
}

export default Dashboard;
