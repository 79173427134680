import * as firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';

const API = firebase.initializeApp({
  apiKey: 'AIzaSyCJhl2AnrtssuqqOuJAULxC9o-FwiIG2yo',
  authDomain: 'blh-consulting.firebaseapp.com',
  databaseURL: 'https://blh-consulting.firebaseio.com',
  projectId: 'blh-consulting',
  storageBucket: 'blh-consulting.appspot.com',
  messagingSenderId: '906276546401',
  appId: '1:906276546401:web:91352e40b4087aef'
});

export default API;
