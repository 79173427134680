import React, { Component, Fragment } from 'react';
import Close from '@material-ui/icons/Close';
import Done from '@material-ui/icons/Done';
import { withStyles } from '@material-ui/core';

import { clone } from '../utils';
import API from '../API';

const styles = {
  closeIcon: {
    color: 'gray',
    fontSize: '18px',
    marginLeft: '5px',
    cursor: 'pointer'
  },

  headerContainer: {
    display: 'flex'
  }
};

class DetailsEdit extends Component {
  state = {
    ddp: { priceKg: '', qtyKg: '', pickupCost: '' },
    ddu: { cbmCost: '', dutyRate: '', pickupCost: '' }
  };

  componentDidMount() {
    const { offer } = this.props;

    if (offer.type === 'DDP') {
      const { pickupCost, priceKg, qtyKg } = offer;
      this.setState({
        ddp: {
          priceKg,
          qtyKg,
          pickupCost
        }
      });
    } else {
      const { cbmCost, dutyRate, pickupCost } = offer;

      this.setState({
        ddu: {
          cbmCost,
          dutyRate,
          pickupCost
        }
      });
    }
  }

  handleSave = () => {
    const { productIndex, index, data, offer } = this.props;
    const newData = clone(data);

    if (offer.type === 'DDP') {
      const db = API.firestore();
      const { priceKg, qtyKg, pickupCost } = this.state.ddp;
      const offer = newData.products[productIndex].offerList[index];

      offer.priceKg = priceKg;
      offer.qtyKg = qtyKg;
      offer.pickupCost = pickupCost;

      db.collection('orders')
        .where('id', '==', data.id)
        .get()
        .then(querySnap => {
          querySnap.forEach(doc => {
            db.collection('orders')
              .doc(doc.id)
              .update(newData)
              .then(() => this.props.getData(), this.props.handleClose());
          });
        });
    } else if (offer.type === 'DDU') {
      const db = API.firestore();
      const { cbmCost, dutyRate, pickupCost } = this.state.ddu;
      const offer = newData.products[productIndex].offerList[index];

      offer.cbmCost = cbmCost;
      offer.dutyRate = dutyRate;
      offer.pickupCost = pickupCost;

      db.collection('orders')
        .where('id', '==', data.id)
        .get()
        .then(querySnap => {
          querySnap.forEach(doc => {
            db.collection('orders')
              .doc(doc.id)
              .update(newData)
              .then(() => this.props.getData(), this.props.handleClose());
          });
        });
    }
  };

  setValue = (e, key, type) => {
    const newState = clone(this.state);

    newState[type][key] = e.target.value;
    this.setState(newState);
  };

  render() {
    const { offer, classes, handleClose } = this.props;

    if (offer.type === 'DDP') {
      const { priceKg, qtyKg, pickupCost } = this.state.ddp;

      return (
        <div>
          <div className={classes.headerContainer}>
            <span>Цена КГ:</span>
            <Done onClick={this.handleSave} className={classes.closeIcon} />
            <Close
              onClick={() => handleClose()}
              className={classes.closeIcon}
            />
          </div>
          <input
            type="number"
            value={priceKg}
            onChange={e => this.setValue(e, 'priceKg', 'ddp')}
          />
          Кол-во КГ:
          <input
            type="number"
            value={qtyKg}
            onChange={e => this.setValue(e, 'qtyKg', 'ddp')}
          />
          <div>{`Фрахт: $${parseFloat(priceKg) *
            parseFloat(qtyKg).toFixed(2)},`}</div>
          PickUp:
          <input
            type="number"
            value={pickupCost}
            onChange={e => this.setValue(e, 'pickupCost', 'ddp')}
          />
        </div>
      );
    } else if (offer.type === 'DDU') {
      const { cbmCost, dutyRate, pickupCost } = this.state.ddu;
      const { totalVolume, invoiceAmount } = this.props.productData;
      const { volume } = offer;

      return (
        <Fragment>
          <div className={classes.headerContainer}>
            <span>Цена CBM:</span>
            <Done onClick={this.handleSave} className={classes.closeIcon} />
            <Close
              onClick={() => handleClose()}
              className={classes.closeIcon}
            />
          </div>
          <input
            value={cbmCost}
            onChange={e => this.setValue(e, 'cbmCost', 'ddu')}
            type="number"
          />
          Объем: <div>{`$${volume || totalVolume}`}</div>
          Фрахт:
          <div>{`$${(parseFloat(totalVolume) * cbmCost).toFixed(3)}`}</div>
          % сборов:
          <input
            type="number"
            value={dutyRate}
            onChange={e => this.setValue(e, 'dutyRate', 'ddu')}
          />
          Сбор:
          <div>{`$${((parseFloat(invoiceAmount) * dutyRate) / 100).toFixed(
            2
          )}`}</div>
          PickUp:
          <input
            type="number"
            value={pickupCost}
            onChange={e => this.setValue(e, 'pickupCost', 'ddu')}
          />
          Итог. сумма:
          <div>
            $
            {(
              cbmCost * parseFloat(totalVolume) +
              (parseFloat(invoiceAmount) / 100) * dutyRate +
              parseFloat(pickupCost)
            ).toFixed(2)}
          </div>
        </Fragment>
      );
    }
  }
}

export default withStyles(styles)(DetailsEdit);
