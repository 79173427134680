import React, { Component } from 'react';
import { TextField, Grid, Typography, withStyles } from '@material-ui/core';

const styles = theme => ({
  squareInput: {
    width: '70px',
    height: '70px',
    margin: '10px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      height: 'auto',
      margin: '5px'
    }
  },

  mainGrid: {
    height: '330px',
    flexWrap: 'nowrap'
  },

  hidden: {
    display: 'none'
  },

  helperText: {
    width: '300px'
  },

  textSize: {
    width: '70%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
});

class CartonForm extends Component {
  state = {
    cartonsQty: '',
    cartonsQtyErr: false,
    lErr: false,
    l: '',
    wErr: false,
    w: '',
    hErr: false,
    h: '',
    cartonWeightErr: false,
    cartonWeight: ''
  };

  setValue = (key, value) => {
    const { setFormData, index } = this.props;

    this.setState({
      [key]: value,
      [`${key}Err`]: false
    });

    setTimeout(() => setFormData({ ...this.state }, index), 0);
  };

  render() {
    const { disabled, classes, index, hidden } = this.props;
    const {
      cartonsQty,
      cartonsQtyErr,
      lErr,
      l,
      wErr,
      hErr,
      w,
      h,
      cartonWeightErr,
      cartonWeight
    } = this.state;

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        className={`${classes.mainGrid} ${hidden ? classes.hidden : ''}`}
      >
        <Typography
          component="h5"
          align="center"
          className={`${classes.margins} ${classes.cartonSizeMargin}`}
        >
          Carton Size (cm)
        </Typography>
        <Grid container justify="center">
          <TextField
            variant="outlined"
            margin="normal"
            type="number"
            required
            id={`sizeL-${index}`}
            label="L"
            error={lErr}
            name="sizeL"
            autoComplete="sizeL"
            disabled={disabled}
            className={classes.squareInput}
            value={l}
            onChange={event => this.setValue('l', event.target.value)}
            helperText="*размеры ТРАНСПОРТНОЙ коробки, не упаковки каждого изделия."
            FormHelperTextProps={{ classes: { root: classes.helperText } }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            type="number"
            required
            id={`sizeW-${index}`}
            label="W"
            error={wErr}
            name="sizeW"
            disabled={disabled}
            autoComplete="sizeW"
            className={classes.squareInput}
            value={w}
            onChange={event => this.setValue('w', event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            type="number"
            required
            id={`sizeH-${index}`}
            label="H"
            error={hErr}
            disabled={disabled}
            name="sizeH"
            autoComplete="sizeH"
            className={classes.squareInput}
            value={h}
            onChange={event => this.setValue('h', event.target.value)}
          />
        </Grid>
        <TextField
          variant="outlined"
          margin="normal"
          type="number"
          required
          error={cartonsQtyErr}
          id={`cartonsQty-${index}`}
          label="Cartons Quantity"
          name="cartonsQty"
          autoComplete="cartonsQty"
          className={classes.textSize}
          value={cartonsQty}
          onChange={event => this.setValue('cartonsQty', event.target.value)}
          helperText="*количество ТРАНСПОРТНЫХ коробок. Не количество единиц товара."
          FormHelperTextProps={{ classes: { root: classes.helperText } }}
          disabled={disabled}
        />
        <TextField
          variant="outlined"
          margin="normal"
          type="number"
          required
          id={`cartonWeight-${index}`}
          label="Carton Weight(kg)"
          name="cartonWeight"
          disabled={disabled}
          error={cartonWeightErr}
          autoComplete="cartonWeight"
          className={`${classes.textSize} ${classes.bottomCentered}`}
          value={cartonWeight}
          onChange={event => this.setValue('cartonWeight', event.target.value)}
          helperText="*вес ТРАНСПОРТНОЙ коробки."
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(CartonForm);
