import React, { Component } from 'react';
import {
  Grid,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  withStyles,
  CircularProgress
} from '@material-ui/core';

import BillHistory from './BillHistory';
import BillRow from './BillRow';
import API from '../API';
import BillForm from './BillForm';

const styles = {
  loading: {
    width: '100%',
    height: 'calc(800px - 48px)',
    overflow: 'auto'
  }
};

class BillTab extends Component {
  state = {
    data: null,
    updateHistory: false
  };

  componentDidMount() {
    const { id } = this.props;

    const db = API.firestore();

    db.collection('orders')
      .where('id', '==', id)
      .get()
      .then(querySnap => {
        querySnap.forEach(doc => {
          this.setState({
            data: doc.data()
          });
        });
      });
  }

  updHistory = mode => {
    this.setState({
      updateHistory: mode
    });
  };

  render() {
    const { classes } = this.props;
    const { data, updateHistory } = this.state;

    if (data === null) {
      return (
        <Grid
          className={classes.loading}
          container
          justify="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      );
    }

    return (
      <Grid container>
        <Table size="small">
          <TableHead>
            <TableRow>
              {['Рейс', 'Статус', 'Файлы', '', ''].map((name, index) => (
                <TableCell key={`${name}-${index}`}>{name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.products.map((product, index) => {
              const submitedOffer =
                product.offerList &&
                product.offerList.filter(offer => offer.done)[0];

              if (
                product.offerList &&
                product.offerList.length > 0 &&
                submitedOffer
              ) {
                return (
                  <BillRow
                    key={index}
                    index={index}
                    submitedOffer={submitedOffer}
                    offerIndex={product.offerList.indexOf(submitedOffer)}
                    data={data}
                  />
                );
              } else {
                return null;
              }
            })}
          </TableBody>
        </Table>
        <BillForm data={data} updHistory={this.updHistory} />
        <BillHistory
          data={data}
          updateHistory={updateHistory}
          updHistory={this.updHistory}
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(BillTab);
