import React from 'react';
import {
  Drawer,
  ListItem,
  ListItemText,
  List,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles({
  sidebar: {
    width: '300px'
  },

  close: {
    padding: '5px',
    '& > svg': {
      cursor: 'pointer'
    }
  }
});

const Sidebar = props => {
  const toggleDrawer = () => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
  };

  const classes = useStyles();
  const { open, toggleSidebar, category, changeCategory } = props;

  return (
    <Drawer open={open} onClose={toggleSidebar}>
      <div
        role="presentation"
        onClick={toggleDrawer(props.side, false)}
        onKeyDown={toggleDrawer(props.side, false)}
        className={classes.sidebar}
      >
        {/* <Grid justify="flex-end" container className={classes.close}>
          <Close />
        </Grid> */}
        <List>
          {[
            'Новые Запросы',
            'Активные Запросы',
            'Завершенные Запросы',
            'Архивированные',
            'Клиенты',
            'Партнеры'
          ].map((text, index) => (
            <ListItem onClick={() => changeCategory(index)} button key={text}>
              <ListItemText primary={text} selected={category === index} />
            </ListItem>
          ))}
        </List>
      </div>
    </Drawer>
  );
};

export default Sidebar;
