export function formatShippingMethod(data, type) {
  if (type === 'object') {
    return `${data.ae1 ? 'Air Express (3-5 Days)' : ''} ${
      data.ae2 ? 'Air Express (5-7 Days)' : ''
    } ${data.ra ? 'Regular Air (10-12 Days)' : ''} ${
      data.ms ? 'Matson Sea (21-24 Days)' : ''
    } ${data.rs ? 'Regular Sea (30-35 Days)' : ''}`;
  } else if (type === 'string') {
    const variants = {
      ae1: 'Air Express (3-5 Days)',
      ae2: 'Air Express (5-7 Days)',
      ra: 'Regular Air (10-12 Days)',
      ms: 'Matson Sea (21-24 Days)',
      rs: 'Regular Sea (30-35 Days)'
    };

    return variants[data];
  }
}

export function clone(obj) {
  var copy;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || 'object' != typeof obj) return obj;

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (var i = 0, len = obj.length; i < len; i++) {
      copy[i] = clone(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}

export function getPattern(type) {
  const variants = {
    Default: `<i>*The freight cost is subject to change if this invoice is issued prior to goods weighing and measurement at the forwarder's warehouse.</i>
<b><i>*CUSTOMS INSPECTION / EXAM, as well as STORAGE in the USA will be billed at actual cost if such expenses occur.</i></b>`,
    'Full Container': `<i>*Full container load shipments are subject to Amazon detention fees at 95USD/hour after the first hour of detention. Additional destination chassis fee, chassis split fee and storage fee will be assessed where applicable. A separate invoice for the fees will be issued once the actual amount of fees incurred is known. </i>
<b><i>*CUSTOMS INSPECTION / EXAM, as well as STORAGE in the USA will be billed at actual cost if such expenses occur.</i></b>`,
    DDU: `<b>Destination Customs Duties are not included in this invoice and will be paid separately once the customs form for the goods is released.</b>
<i>*The freight cost is subject to change if this invoice is issued prior to goods weighing and measurement at the forwarder's warehouse.</i>
<b><i>*CUSTOMS INSPECTION / EXAM, as well as STORAGE in the USA will be billed at actual cost if such expenses occur.</i></b>`
  };

  return variants[type];
}

export function convertCamel(str) {
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, function(str) {
    return str.toUpperCase();
  });
}
