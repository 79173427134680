import React, { Component } from 'react';
import { AppBar, Grid, Tab, Tabs, withStyles, Modal } from '@material-ui/core';

import PersonalData from './PersonalData';

const styles = theme => ({
  modalGrid: {
    width: '80%',
    'max-height': '800px',
    background: 'white',
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%'
    }
  },

  mainGrid: {
    width: '100%',
    height: '100%'
  },

  tab: {
    width: '100%',
    height: 'calc(800px - 48px)',
    overflow: 'auto'
  }
});

class UserModal extends Component {
  state = {
    currentTab: 0
  };

  changeTab = (e, val) => {
    this.setState({ currentTab: val });
  };

  getTab = () => {
    const { currentTab } = this.state;
    const { userData, partner, updateData } = this.props;

    const tabs = [
      <PersonalData
        userData={userData}
        updateData={updateData}
        partner={partner}
      />
    ];

    return tabs[currentTab];
  };

  render() {
    const { open, classes, onClose } = this.props;
    const { currentTab } = this.state;

    return (
      <Modal onClose={onClose} open={open}>
        <div className={classes.modalGrid}>
          <Grid container justify="center" className={classes.mainGrid}>
            <AppBar position="static">
              <Tabs
                value={currentTab}
                onChange={this.changeTab}
                centered={true}
                scrollButtons="on"
              >
                <Tab value={0} label="Личные данные" />
              </Tabs>
            </AppBar>
            <div className={classes.tab}>{this.getTab()}</div>
          </Grid>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(UserModal);
