import React, { Component, Fragment } from 'react';
import {
  Typography,
  withStyles,
  TextField,
  Container,
  Tabs,
  Grid,
  Tab,
  AppBar,
  Button,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  FormControl
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Plus from '@material-ui/icons/Add';
import Minus from '@material-ui/icons/Remove';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ClientAddForm from '../components/ClientAddForm';

import ProductForm from '../components/ProductForm';
import Notification from '../components/Notifications';

import firebase from 'firebase';
import API from '../API';

const styles = theme => ({
  header: {
    margin: '20px'
  },

  tabBar: {
    marginBottom: '10px',
    marginTop: '10px'
  },

  note: {
    marginTop: '10px'
  },

  clientName: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },

  controlForm: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '30px',
      cursor: 'pointer'
    }
  },

  addTab: {
    fontSize: 25,
    opacity: 1
  },

  closeIcon: {
    fontSize: '20px',
    visibility: 'hidden'
  },

  close: {
    margin: '10px',
    color: 'red',
    cursor: 'pointer'
  },

  tabWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    '&:hover svg': {
      visibility: 'visible'
    }
  },

  disableForm: {
    opacity: '0.6'
  },

  tabName: {
    marginLeft: 'auto',
    marginRight: 'auto'
  }
});
class UserPage extends Component {
  state = {
    clientName: '',
    clientNameErr: false,
    pickupFrom: '',
    pickupFromErr: false,
    productCount: 1,
    productsList: [0],
    maxCount: 1,
    currentTab: 0,
    submitLoading: false,
    errorMsg: '',
    note: '',
    isAdmin: false,
    isExpand: false,
    clients: [],
    selectedClient: { name: '', uid: '' }
  };

  genProductForms = titles => {
    const { currentTab, productsList, submitLoading } = this.state;
    const { classes } = this.props;
    const filteredList = productsList.filter(product => product !== null);

    return filteredList.map((product, index) => {
      if (titles) {
        return (
          <Tab
            label={
              <div className={classes.tabWrapper}>
                <span className={classes.tabName}>{`Product ${index +
                  1}`}</span>
                <Close
                  onClick={() => this.removeTab(product)}
                  className={classes.closeIcon}
                />
              </div>
            }
            key={`label-${index}`}
          />
        );
      } else {
        this[`ref${index}`] = React.createRef();

        return (
          <ProductForm
            ref={this[`ref${index}`]}
            hidden={currentTab !== index}
            disabled={submitLoading}
            key={`form-${product}`}
            index={index}
            firstFormRef={this.ref0}
          />
        );
      }
    });
  };

  validateAllData = data => {
    let formError = false;
    for (let key in data) {
      if (data.hasOwnProperty(key) && data[key] === '' && key !== 'note') {
        this.setState({
          [`${key}Err`]: true
        });

        formError = true;
      }
    }

    const errors = [];
    const cartonsError = [];

    data.products.forEach((product, index) => {
      const productForm = this[`ref${index}`].current;

      let error = false;
      let cartonError = false;
      for (let key in product) {
        if (product.hasOwnProperty(key) && product[key] === '') {
          productForm.setState({
            [`${key}Err`]: true
          });

          error = true;
        }
      }

      cartonsError[index] = [];

      product.cartonsData.forEach((carton, cartonIndex) => {
        for (let key in carton) {
          if (carton.hasOwnProperty(key) && carton[key] === '') {
            productForm[`ref${cartonIndex}`].current.setState({
              [`${key}Err`]: true
            });

            error = true;
            cartonError = true;
          }
        }

        if (cartonError) {
          cartonsError[index].push(cartonIndex + 1);
        }
      });

      const shippingMethods = [
        product.ae1,
        product.ae2,
        product.ms,
        product.ra,
        product.rs
      ];

      const checkboxCheck = !shippingMethods.some(elem => elem === true);

      if (error || checkboxCheck) {
        if (checkboxCheck) {
          productForm.setState({
            checkboxErr: true
          });
        }
      }

      if (error || cartonError) {
        errors.push(index);
      }
    });

    if (errors.length !== 0) {
      let errorStr = '';
      errors.forEach((error, index) => {
        errorStr += `Product ${error + 1} ${
          cartonsError[error].length !== 0
            ? `Cartons ${cartonsError[error].join(',')}`
            : ''
        } ${errors.length > index + 1 ? ', ' : ''}`;
      });

      errorStr += ': Empty required fields';

      this.setState({
        errorMsg: errorStr
      });

      return false;
    } else if (!formError) {
      return true;
    }
  };

  handleChange = (event, newValue) => {
    const { submitLoading } = this.state;
    if (!submitLoading) {
      this.setState({
        currentTab: newValue
      });
    }
  };

  handleClear = () => {
    this.setState({
      clientName: '',
      clientNameErr: false,
      pickupFrom: '',
      pickupFromErr: false,
      productCount: 1,
      productsList: [0],
      maxCount: 1,
      currentTab: 0,
      submitLoading: false,
      errorMsg: ''
    });

    this.ref0.current.setState({
      productName: '',
      productNameErr: false,
      hsCode: '',
      hsCodeErr: false,
      l: '',
      lErr: false,
      w: '',
      wErr: false,
      h: '',
      hErr: false,
      cartonsQty: '',
      cartonsQtyErr: false,
      cartonWeight: '',
      cartonWeightErr: false,
      invoiceAmount: '',
      invoiceAmountErr: false,
      shipTo: '',
      shipToErr: false,
      ae1: false,
      ae2: false,
      ra: false,
      ms: false,
      rs: false,
      shippingTerms: 'FOB'
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  handleSubmit = () => {
    const { uid, history, displayName } = this.props;
    const { isAdmin, selectedClient, clientName } = this.state;

    this.setState({
      submitLoading: true
    });

    const db = API.firestore();
    const data = this.getAllData();

    data.clientName = isAdmin ? selectedClient.name : clientName;
    if (this.validateAllData(data)) {
      // Get all photos from all products
      Promise.all(
        data.products.map(product => {
          return Promise.all(
            product.photos.map(photo => {
              const storageRef = API.storage().ref(
                `${Date.now()}-${photo.name}`
              );
              return storageRef.put(photo);
            })
          );
        })
      ).then(snaps => {
        // Get all url from photos
        Promise.all(
          snaps.map(snap => {
            return Promise.all(snap.map(photo => photo.ref.getDownloadURL()));
          })
        ).then(urls => {
          urls.forEach((url, index) => {
            data.products[index].photos = url.map((link, idx) => ({
              name: data.products[index].photos[idx].name,
              url: link
            }));
          });

          data.uid = isAdmin ? selectedClient.uid : uid;
          data.displayName = isAdmin ? selectedClient.name : displayName;
          if (isAdmin) {
            data.clientName = selectedClient.name;
          }
          data.status = 'new';
          data.sendDate = '';
          db.collection('userDetails')
            .doc(uid)
            .get()
            .then(res => {
              data.companyName = res.data() ? res.data().companyName : '';
              db.collection('stats')
                .doc('6v6DTTiYV2pAMQKf3568')
                .get()
                .then(res => {
                  const count = res.data().count;
                  const date = new Date();
                  data.id = `BLH${date.getUTCFullYear()}${date.getMonth() +
                    1}${date.getDate()}${count + 1}`;
                  data.createDate = date;
                  db.collection('orders')
                    .add(data)
                    .then(() => {
                      const increment = firebase.firestore.FieldValue.increment(
                        1
                      );
                      db.collection('stats')
                        .doc('6v6DTTiYV2pAMQKf3568')
                        .update({
                          count: increment
                        })
                        .then(() => {});
                      if (!isAdmin) {
                        fetch(
                          'https://us-central1-blh-consulting.cloudfunctions.net/sendData',
                          {
                            method: 'POST',
                            body: JSON.stringify(data)
                          }
                        ).then(() => {
                          this.setState({
                            submitLoading: false
                          });
                          history.push('/success');
                        });
                      } else {
                        history.push('/');
                      }
                    });
                });
            });
        });
      });
    } else {
      this.setState({
        submitLoading: false
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  addTab = () => {
    const { productCount, productsList, maxCount, submitLoading } = this.state;

    if (!submitLoading) {
      const newList = [...productsList];
      newList.push(maxCount);

      this.setState({
        productsList: newList,
        productCount: productCount + 1,
        maxCount: maxCount + 1
      });
    }
  };

  removeTab = index => {
    const { productCount, submitLoading } = this.state;

    if (!submitLoading && productCount !== 1) {
      const { productsList, productCount, currentTab } = this.state;
      const newList = [...productsList];
      newList[index] = null;

      const newState = {
        productsList: newList,
        productCount: productCount - 1,
        currentTab:
          currentTab === productCount - 1 && currentTab !== 0
            ? currentTab - 1
            : currentTab
      };

      setTimeout(() => this.setState(() => newState), 0);
    }
  };

  getAllData = () => {
    const { productCount, pickupFrom, clientName } = this.state;
    const result = {
      clientName,
      pickupFrom,
      products: []
    };

    for (let i = 0; i < productCount; i += 1) {
      const productData = this[`ref${i}`].current.state;
      productData.totalWeight = this[`ref${i}`].current.genTotalData(
        'totalWeight'
      );
      productData.totalVolume = this[`ref${i}`].current.genTotalData(
        'totalVolume'
      );
      productData.volumeWeight = this[`ref${i}`].current.genTotalData(
        'volumeWeight'
      );
      productData.volumeWeightExpress = this[`ref${i}`].current.genTotalData(
        'volumeWeightExpress'
      );
      result.products.push(productData);
    }

    return result;
  };

  componentDidMount() {
    const { uid } = this.props;

    const db = API.firestore();

    db.collection('userDetails')
      .doc(uid)
      .get()
      .then(doc => {
        if (doc.exists) {
          const data = doc.data();
          this.setState({
            clientName: `${API.auth().currentUser.displayName} ${
              data.companyName !== '' ? `, ${data.companyName}` : ''
            }`
          });
        }
      });

    this.getUserList();
  }

  getUserList = clientName => {
    const { uid, location, history } = this.props;
    const db = API.firestore();

    db.collection('admins')
      .where('uid', '==', uid)
      .get()
      .then(querySnap => {
        if (!querySnap.empty) {
          this.setState({ isAdmin: true });
          db.collection('userDetails')
            .get()
            .then(querySnap => {
              querySnap.forEach(doc => {
                const data = doc.data();
                this.setState(prevState => {
                  const client = {
                    name: `${data.firstName} ${data.lastName}`,
                    uid: doc.id
                  };
                  const clients = [...prevState.clients, client];

                  if (
                    typeof clientName !== 'undefined' &&
                    client.name.trim() === clientName
                  ) {
                    return {
                      clients,
                      selectedClient: client
                    };
                  }

                  return {
                    clients
                  };
                });
              });
            });
        }
        if (querySnap.empty && location.pathname === '/addProduct')
          history.push('/');
      });
  };

  setValue = (key, value) => {
    this.setState({
      [key]: value,
      [`${key}Err`]: false
    });
  };

  setClient = e => {
    this.setState({
      selectedClient: e.target.value
    });
  };

  render() {
    const { classes, history } = this.props;
    const {
      clientName,
      pickupFrom,
      currentTab,
      submitLoading,
      clientNameErr,
      pickupFromErr,
      errorMsg,
      note,
      isAdmin,
      clients,
      selectedClient,
      isExpand
    } = this.state;

    return (
      <Fragment>
        {isAdmin ? (
          <Close
            className={classes.close}
            fontSize={'large'}
            onClick={() => history.push('/')}
          />
        ) : null}
        <Typography
          className={`${classes.header} ${
            submitLoading ? classes.disableForm : ''
          }`}
          component="h1"
          align="center"
          variant="h5"
        >
          Order Form
        </Typography>
        {errorMsg ? (
          <Notification
            variant="error"
            message={errorMsg}
            onClose={() => this.setState({ errorMsg: '' })}
          />
        ) : null}
        <Container className={submitLoading ? classes.disableForm : ''}>
          {isAdmin ? (
            <div className={classes.controlForm}>
              <FormControl className={classes.clientName}>
                <InputLabel>Clients:</InputLabel>
                <Select value={selectedClient} onChange={this.setClient}>
                  {clients.map(client => (
                    <MenuItem key={client.uid} value={client}>
                      {client.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className={classes.controlForm}>
                {isExpand ? (
                  <Minus onClick={() => this.setState({ isExpand: false })} />
                ) : (
                  <Plus onClick={() => this.setState({ isExpand: true })} />
                )}
              </div>
            </div>
          ) : (
            <TextField
              variant="outlined"
              margin="normal"
              required
              disabled={submitLoading}
              id="clientName"
              label="Client Name"
              name="clientName"
              autoComplete="clientName"
              error={clientNameErr}
              value={clientName}
              className={classes.clientName}
              onChange={event =>
                this.setValue('clientName', event.target.value)
              }
              autoFocus
              helperText="*ваше имя или название вашей компании"
            />
          )}
          {isAdmin && isExpand ? (
            <ClientAddForm isTable={false} getUserList={this.getUserList} />
          ) : null}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="pickupFrom"
            label="Pickup From"
            disabled={submitLoading}
            name="pickupFrom"
            autoComplete="pickupFrom"
            error={pickupFromErr}
            value={pickupFrom}
            onChange={event => this.setValue('pickupFrom', event.target.value)}
            helperText="*адрес производителя товара в Китае"
          />
          <AppBar position="static" className={classes.tabBar}>
            <Tabs
              value={currentTab}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="simple tabs example"
              onChange={this.handleChange}
            >
              {this.genProductForms(true)}
              <Tab onClick={this.addTab} className={classes.addTab} label="+" />
            </Tabs>
          </AppBar>
          {this.genProductForms()}
          <Grid container justify="center" className={classes.note}>
            <TextField
              placeholder="Info"
              variant="outlined"
              fullWidth
              multiline={true}
              rows={2}
              rowsMax={4}
              value={note}
              disabled={submitLoading}
              onChange={event => this.setValue('note', event.target.value)}
            />
          </Grid>

          <Grid container justify="center">
            {submitLoading ? (
              <Grid
                container
                alignItems="center"
                justify="center"
                className={classes.header}
              >
                <CircularProgress size={36} />
              </Grid>
            ) : (
              <Fragment>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.header}
                  onClick={this.handleSubmit}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.header}
                  onClick={this.handleClear}
                >
                  Clear
                </Button>
              </Fragment>
            )}
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  uid: state.firebase.auth.uid,
  displayName: state.firebase.auth.displayName
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withRouter
)(UserPage);
