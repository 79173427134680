import React, { Component } from 'react';
import {
  withStyles,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress
} from '@material-ui/core';

import { getPattern } from '../utils';
import BillFormRow from './BillFormRow';
import SelectInput from './SelectInput';

import API from '../API';

const styles = {
  mainGrid: {
    margin: '20px 0 20px 0'
  },

  textInput: {
    margin: '10px',
    width: '150px'
  },

  textArea: {
    width: '500px'
  },

  addFormButton: {
    margin: '20px'
  },

  disabledInput: {
    '& input': {
      color: 'black'
    }
  }
};

class BillForm extends Component {
  state = {
    patternType: 'Default',
    pattern: '',
    forms: [1],
    sum: [0],
    isLoading: false,
    productName: ''
  };

  handleDelete = formKey => {
    const { forms, sum } = this.state;

    if (forms.length !== 1) {
      const newForms = [...forms];
      const newSum = [...sum];
      newSum[formKey - 1] = null;
      const pos = newForms.indexOf(formKey);
      newForms.splice(pos, 1);

      this.setState({
        forms: newForms,
        sum: newSum
      });
    }
  };

  componentDidMount() {
    const { patternType } = this.state;
    const { data } = this.props;

    this.setState({
      pattern: getPattern(patternType),
      productName: data.products[0].productName
    });
  }

  addForm = () => {
    const { forms } = this.state;

    this.setState({
      forms: [...forms, forms[forms.length - 1] + 1]
    });
  };

  getSum = (value, key) => {
    const newSum = [...this.state.sum];
    newSum[key - 1] = value;

    this.setState({
      sum: newSum
    });
  };

  setSum = () => {
    const { sum } = this.state;
    return sum
      .filter(val => val !== null)
      .reduce((prev, cur) => parseFloat(prev) + parseFloat(cur));
  };

  handleSubmit = () => {
    this.setState({
      isLoading: true
    });

    const { forms, pattern, productName } = this.state;
    const { data, updHistory } = this.props;

    const clientName = data.clientName;
    const totalSum = this.setSum();

    const formsData = forms.map(form => {
      return this[`ref-${form}`].current.state;
    });

    const db = API.firestore();

    db.collection('stats')
      .doc('6v6DTTiYV2pAMQKf3568')
      .get()
      .then(docSnap => {
        const stats = docSnap.data();
        const { invoiceId } = stats;

        fetch('https://us-central1-blh-consulting.cloudfunctions.net/genPDF', {
          method: 'POST',
          body: JSON.stringify({
            pattern,
            sum: totalSum,
            formsData,
            productName,
            clientName,
            invoiceId
          })
        })
          .then(res => res.blob())
          .then(pdf => {
            this.setState({
              isLoading: false
            });

            const fileURL = URL.createObjectURL(pdf);
            window.open(fileURL);

            this.setState({
              patternType: 'Default',
              pattern: getPattern('Default'),
              forms: [1],
              sum: [0],
              isLoading: false,
              productName: data.products[0].productName
            });
            this[`ref-${1}`].current &&
              this[`ref-${1}`].current.setState({
                info: '',
                unit: '',
                qty: '',
                price: ''
              });

            const db = API.firestore();

            db.collection('orders')
              .where('id', '==', data.id)
              .get()
              .then(querySnap => {
                querySnap.forEach(doc => {
                  const history = {
                    rest: '',
                    balance: {
                      must: '',
                      due: ''
                    },
                    sum: '',
                    payTo: Date.now(),
                    accNum: '',
                    invoice: Date.now(),
                    invoiceId,
                    pdf: {
                      pattern,
                      sum: totalSum,
                      formsData,
                      productName,
                      clientName
                    }
                  };
                  if (data.offerHistory === undefined) {
                    data.offerHistory = [];
                  }
                  data.offerHistory.push(history);

                  db.collection('orders')
                    .doc(doc.id)
                    .update(data)
                    .then(() => {
                      db.collection('stats')
                        .doc('6v6DTTiYV2pAMQKf3568')
                        .update({ invoiceId: invoiceId + 1 });
                      updHistory(true);
                    });
                });
              });
          });
      });
  };

  render() {
    const { classes } = this.props;
    const { patternType, pattern, forms, isLoading, productName } = this.state;

    return (
      <Grid
        container
        className={classes.mainGrid}
        alignItems="center"
        direction="column"
      >
        <Typography variant="h5">Форма оплаты</Typography>
        <Grid container>
          {forms.map(form => {
            this[`ref-${form}`] = React.createRef();
            return (
              <BillFormRow
                ref={this[`ref-${form}`]}
                getSum={this.getSum}
                formKey={form}
                onDelete={this.handleDelete}
                key={form}
              />
            );
          })}
        </Grid>
        <Button
          className={classes.addFormButton}
          onClick={this.addForm}
          variant="contained"
          color="primary"
        >
          Добавить форму
        </Button>
        <Grid container justify="center" alignItems="center">
          <TextField
            value={this.setSum()}
            label="Сумма"
            className={`${classes.textInput} ${classes.disabledInput}`}
            disabled
          />
          <SelectInput
            value={patternType}
            classes={classes.textInput}
            values={['Default', 'Full Container', 'DDU']}
            onChange={e =>
              this.setState({
                patternType: e.target.value,
                pattern: getPattern(e.target.value)
              })
            }
          />
          <TextField
            value={pattern}
            label="Шаблон"
            className={`${classes.textInput} ${classes.textArea}`}
            multiline
            rows={5}
            onChange={e => this.setState({ pattern: e.target.value })}
          />
          <TextField
            value={productName}
            label="Назв. продукта"
            className={classes.textInput}
            onChange={e => this.setState({ productName: e.target.value })}
          />
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleSubmit}
            >
              PDF
            </Button>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(BillForm);
