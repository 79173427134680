import React, { Component } from 'react';
import {
  TableRow,
  TableCell,
  TextField,
  withStyles,
  Grid,
  Button
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { clone, getPattern } from '../utils';
import DateFnsUtils from '@date-io/date-fns';
import SelectInput from './SelectInput';

const styles = {
  textDue: {
    '& input': {
      color: 'green'
    }
  },

  textMust: {
    '& input': {
      color: 'red'
    }
  },

  balanceInput: {
    width: '50px',
    '& input': {
      textAlign: 'center'
    }
  },

  balanceGrid: {
    flexWrap: 'nowrap'
  },

  addInfo: {
    background: '#F5F5F5'
  },

  ml: {
    marginLeft: '10px'
  },

  textInput: {
    width: '100px'
  },

  controlPaid: {
    display: 'flex',
    flexWrap: 'nowrap'
  }
};

class BillHistoryRow extends Component {
  state = {
    expanded: false,
    patternType: '',
    accNum: '',
    balance: {
      due: '',
      must: ''
    },
    invoice: Date.now(),
    invoiceId: '',
    payTo: Date.now(),
    pdf: {
      clientName: '',
      formsData: [],
      pattern: '',
      productName: '',
      sum: ''
    },
    rest: '',
    sum: ''
  };

  componentDidMount() {
    const { history } = this.props;

    this.setState(history);
  }

  setValue = (key, val, deepKey) => {
    if (deepKey) {
      const newData = clone(this.state[deepKey]);
      newData[key] =
        key === 'due'
          ? parseFloat(val) > parseFloat(this.state.pdf.sum)
            ? this.state.pdf.sum
            : val
          : val;
      this.setState({
        [deepKey]: newData
      });
      return;
    }

    this.setState({
      [key]: val
    });
  };

  getPdf = () => {
    fetch('https://us-central1-blh-consulting.cloudfunctions.net/genPDF', {
      method: 'POST',
      body: JSON.stringify({
        ...this.state.pdf,
        invoiceId: this.state.invoiceId
      })
    })
      .then(res => res.blob())
      .then(pdf => {
        const fileURL = URL.createObjectURL(pdf);
        window.open(fileURL);
      });
  };

  setFormData = (val, key, index) => {
    const newPdf = clone(this.state.pdf);

    newPdf.formsData[index][key] = val;
    if (key === 'unit' || key === 'qty') {
      newPdf.formsData[index]['price'] =
        parseFloat(newPdf.formsData[index].qty) *
        parseFloat(newPdf.formsData[index].unit);
    }
    newPdf.sum =
      newPdf.formsData.length > 1
        ? newPdf.formsData.reduce((acc, cur) => {
            return (
              parseFloat(acc.qty) * parseFloat(acc.unit) +
              parseFloat(cur.qty) * parseFloat(cur.unit)
            );
          })
        : parseFloat(newPdf.formsData[0].qty) *
          parseFloat(newPdf.formsData[0].unit);

    this.setState({ pdf: newPdf });
  };

  deletePay = index => {
    const newPdf = clone(this.state.pdf);

    newPdf.formsData.splice(index, 1);

    this.setState({ pdf: newPdf });
  };

  addPay = () => {
    const newPdf = clone(this.state.pdf);

    newPdf.formsData.push({
      info: '',
      unit: '',
      qty: '',
      price: ''
    });

    this.setState({
      pdf: newPdf
    });
  };

  render() {
    const { classes } = this.props;

    const duty = this.state.pdf && this.state.pdf.sum - this.state.balance.due;

    return (
      <>
        <TableRow>
          <TableCell>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.setState({ expanded: !this.state.expanded })}
            >
              {!this.state.expanded
                ? 'Открыть доп. инфу.'
                : 'Закрыть доп. инфу.'}
            </Button>
          </TableCell>
          {['invoiceId', 'invoice', 'payTo', 'sum'].map((category, index) => {
            if (category === 'invoice' || category === 'payTo') {
              return (
                <TableCell key={`${category}-${index}`}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      format="dd/MM/yyyy"
                      value={this.state[category]}
                      onChange={date => this.setValue(category, date.getTime())}
                    />
                  </MuiPickersUtilsProvider>
                </TableCell>
              );
            }

            return (
              <TableCell key={`${category}-${index}`}>
                <TextField
                  value={
                    category !== 'sum'
                      ? this.state[category]
                      : (this.state.pdf && this.state.pdf.sum) || ''
                  }
                  disabled={category === 'invoiceId'}
                  onChange={e => this.setValue(category, e.target.value)}
                />
              </TableCell>
            );
          })}
          <TableCell>
            <TextField
              value={this.state.pdf && this.state.pdf.clientName}
              onChange={e => this.setValue('clientName', e.target.value, 'pdf')}
            />
          </TableCell>
          <TableCell>
            <Grid container alignItems="center" className={classes.balanceGrid}>
              <TextField
                value={(this.state.balance && this.state.balance.due) || ''}
                onChange={e => this.setValue('due', e.target.value, 'balance')}
                className={`${classes.textDue} ${classes.balanceInput}`}
                type="number"
                inputProps={{
                  max: (this.state.pdf && this.state.pdf.sum) || ''
                }}
              />
              |
              <TextField
                disabled
                value={duty < 0 ? 0 : duty || ''}
                onChange={e => this.setValue('must', e.target.value, 'balance')}
                className={`${classes.textMust} ${classes.balanceInput}`}
                type="number"
              />
            </Grid>
          </TableCell>
          <TableCell>
            <Button variant="contained" color="primary" onClick={this.getPdf}>
              Get
            </Button>
          </TableCell>
        </TableRow>
        {this.state.expanded
          ? this.state.pdf &&
            this.state.pdf.formsData.map((data, index) => {
              const lastElement = index === this.state.pdf.formsData.length - 1;

              return (
                <>
                  <TableRow key={index} className={classes.addInfo}>
                    <TableCell />
                    <TableCell />
                    <TableCell>
                      <SelectInput
                        value={this.state.patternType}
                        classes={classes.textInput}
                        values={['Default', 'Full Container', 'DDU']}
                        label="Шаблон"
                        onChange={e => {
                          this.setValue(
                            'pattern',
                            getPattern(e.target.value),
                            'pdf'
                          );
                          this.setState({ patternType: e.target.value });
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        onChange={e =>
                          this.setValue('pattern', e.target.value, 'pdf')
                        }
                        value={this.state.pdf.pattern}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        onChange={e =>
                          this.setValue('productName', e.target.value, 'pdf')
                        }
                        value={this.state.pdf.productName}
                      />
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                  <TableRow className={classes.addInfo}>
                    <TableCell />

                    <TableCell>
                      <TextField
                        label="Инфо."
                        value={this.state.pdf.formsData[index].info}
                        onChange={e =>
                          this.setFormData(e.target.value, 'info', index)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Ед."
                        value={this.state.pdf.formsData[index].unit}
                        onChange={e =>
                          this.setFormData(e.target.value, 'unit', index)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Кол."
                        value={this.state.pdf.formsData[index].qty}
                        onChange={e =>
                          this.setFormData(e.target.value, 'qty', index)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Цена"
                        value={this.state.pdf.formsData[index].price}
                        onChange={e =>
                          this.setFormData(e.target.value, 'price', index)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.controlPaid}>
                        <Button
                          variant="contained"
                          onClick={() => this.deletePay(index)}
                        >
                          Удалить
                        </Button>
                        {lastElement ? (
                          <Button
                            className={classes.ml}
                            onClick={() => this.addPay()}
                            variant="contained"
                          >
                            Добавить
                          </Button>
                        ) : null}
                      </div>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell />
                  </TableRow>
                </>
              );
            })
          : null}
      </>
    );
  }
}

export default withStyles(styles)(BillHistoryRow);
