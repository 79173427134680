import React, { Component } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import Done from '@material-ui/icons/Done';

const styles = {
  root: {
    height: 'calc(100vh - 60px)',
    width: '100vw'
  },

  successContainer: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    backgroundColor: '#3CB371',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  successIcon: {
    fontSize: 50,
    color: 'white'
  }
};

class SuccessPage extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.history.push('/');
    }, 5000);
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid
        className={classes.root}
        container
        justify="center"
        alignItems="center"
      >
        <div className={classes.successContainer}>
          <Done className={classes.successIcon} />
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(SuccessPage);
