import React, { Component } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core';

import OfferRow from './OfferRow';

const styles = {
  stickyHead: {
    '& th': {
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      zIndex: 10
    }
  }
};

class OfferTable extends Component {
  state = {
    done: false
  };

  setDone = done => {
    this.setState({
      done
    });
  };

  componentDidMount() {
    const { product } = this.props;

    if (product.offerList.filter(offer => offer.done).length === 1) {
      this.setDone(true);
    }
  }

  render() {
    const { product, data, productIndex, getData, classes } = this.props;

    return (
      <Table size="small">
        <TableHead className={classes.stickyHead}>
          <TableRow>
            {[
              'Перевозчик',
              'Подробности',
              'Метод Перевозки',
              'Стоимость',
              'CBM или KG',
              'Наша цена за ед.',
              'Кол-во ед.',
              'Сумма',
              'Наш PickUp',
              'Прибыль',
              ''
            ].map(category => (
              <TableCell key={category}>{category}</TableCell>
            ))}
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {product.offerList.map((offer, index) => {
            this[`ref${index}`] = React.createRef();
            return (
              <OfferRow
                productIndex={productIndex}
                data={data}
                key={index}
                getData={getData}
                offer={offer}
                product={product}
                index={index}
                setDone={this.setDone}
                ref={this[`ref${index}`]}
                alreadyDone={this.state.done}
                deleteTender={this.props.deleteTender}
                save={this.props.save}
              />
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

export default withStyles(styles)(OfferTable);
