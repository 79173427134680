import React, { Component } from 'react';
import { TextField, Button, CircularProgress, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import Notification from '../components/Notifications';

import API from '../API';
class LoginForm extends Component {
  state = {
    firstName: '',
    lastName: '',
    companyName: '',
    phone: '',
    wechat: '',
    email: '',
    pass: '',
    passConf: '',
    errorMsg: '',
    loading: false
  };

  handleChange = (event, name) => {
    const changes = {};
    changes[name] = event.target.value;

    this.setState(changes);
  };

  handleSubmit = () => {
    const {
      email,
      pass,
      passConf,
      firstName,
      lastName,
      wechat,
      companyName,
      phone
    } = this.state;

    const { history } = this.props;

    if (!email || !pass || !passConf || !firstName || !lastName) {
      this.setState({
        errorMsg: 'Empty required fields.'
      });
      return;
    }

    this.setState({
      loading: true
    });

    API.auth()
      .createUserWithEmailAndPassword(email, pass)
      .then(() => {
        API.auth().onAuthStateChanged(user => {
          if (user) {
            if (user.customClaims && user.customClaims.moderator === true) {
              return;
            }

            user
              .updateProfile({
                displayName: `${firstName} ${lastName}`
              })
              .then(() => {
                const db = API.firestore();

                db.collection('userDetails')
                  .doc(user.uid)
                  .set({
                    firstName,
                    lastName,
                    phone,
                    wechat,
                    companyName
                  })
                  .then(() => {
                    this.setState({
                      loading: false
                    });
                    history.push('/');
                  });
              })
              .catch(err => {
                this.setState({
                  errorMsg: err.message
                });
              });
          }
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          errorMsg: err.message
        });
      });
  };

  render() {
    const {
      wechat,
      companyName,
      phone,
      firstName,
      lastName,
      email,
      pass,
      passConf,
      errorMsg,
      loading
    } = this.state;

    return (
      <form noValidate>
        {errorMsg ? (
          <Notification
            variant="error"
            message={errorMsg}
            onClose={() => this.setState({ errorMsg: '' })}
          />
        ) : null}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="first-name"
          label="First Name / Имя"
          name="firstName"
          autoFocus
          value={firstName}
          onChange={event => this.handleChange(event, 'firstName')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="last-name"
          label="Last Name / Фамилия"
          name="lastName"
          autoFocus
          value={lastName}
          onChange={event => this.handleChange(event, 'lastName')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address / Электронная почта"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={event => this.handleChange(event, 'email')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password / Пароль"
          type="password"
          id="password"
          autoComplete="current-password"
          value={pass}
          onChange={event => this.handleChange(event, 'pass')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="passConf"
          label="Password Confirm / Потверждение Пароля"
          type="password"
          id="passConf"
          value={passConf}
          onChange={event => this.handleChange(event, 'passConf')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="company-name"
          label="Company Name / Имя Компании"
          name="companyName"
          autoFocus
          value={companyName}
          onChange={event => this.handleChange(event, 'companyName')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="phone"
          label="Phone / Телефон"
          name="phone"
          autoFocus
          value={phone}
          onChange={event => this.handleChange(event, 'phone')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="wechat"
          label="WeChat"
          name="wechat"
          autoFocus
          value={wechat}
          onChange={event => this.handleChange(event, 'wechat')}
        />
        {loading ? (
          <Grid container alignItems="center" justify="center">
            <CircularProgress size={36} />
          </Grid>
        ) : (
          <Button
            onClick={this.handleSubmit}
            fullWidth
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        )}
      </form>
    );
  }
}

export default withRouter(LoginForm);
