import React, { Component } from 'react';
import {
  Modal,
  Grid,
  withStyles,
  AppBar,
  Tabs,
  Tab,
  CircularProgress
} from '@material-ui/core';

import OrderData from './OrderData';
import TenderTab from './TenderTab';
import BillTab from './BillTab';

import API from '../API';
import InfoTab from './InfoTab';

const styles = theme => ({
  modalContainer: {
    width: '80%',
    'max-height': '800px',
    background: 'white',
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%'
    }
  },

  mainContainer: {
    width: '100%',
    height: '100%'
  },

  loading: {
    width: '100%',
    height: 'calc(800px - 48px)',
    overflow: 'auto'
  }
});

class DetailModal extends Component {
  state = {
    currentTab: 0,
    data: null
  };

  handleChange = (e, val) => {
    this.setState({ currentTab: val });
  };

  getTab = () => {
    const { currentTab, data } = this.state;
    const { classes } = this.props;

    if (data === null) {
      return (
        <Grid
          className={classes.loading}
          container
          justify="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      );
    }

    const variants = [
      <OrderData data={{ ...data }} />,
      <TenderTab id={data.id} />,
      <BillTab id={data.id} />,
      <InfoTab id={data.id} data={{ ...data }} />
    ];

    return variants[currentTab];
  };

  componentDidMount() {
    const { id } = this.props;
    const db = API.firestore();

    db.collection('orders')
      .where('id', '==', id)
      .get()
      .then(querySnap => {
        querySnap.forEach(doc => {
          this.setState({
            data: doc.data()
          });
        });
      });
  }

  render() {
    const { modalOpen, classes, onClose } = this.props;
    const { currentTab } = this.state;

    return (
      <Modal onClose={onClose} open={modalOpen}>
        <div className={classes.modalContainer}>
          <Grid container justify="center" className={classes.mainContainer}>
            <AppBar position="static">
              <Tabs
                value={currentTab}
                onChange={this.handleChange}
                centered={true}
                scrollButtons="on"
              >
                <Tab value={0} label="Данные Заказа" />
                <Tab value={1} label="Тендер" />
                <Tab value={2} label="Счета" />
                <Tab value={3} label="Информация" />
              </Tabs>
            </AppBar>
            <div className={classes.loading}>{this.getTab()}</div>
          </Grid>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(DetailModal);
