import React, { Component } from 'react';
import { Button, Grid, TextField, withStyles } from '@material-ui/core';

const styles = {
  billField: {
    margin: '0 20px 0 20px'
  },

  infoField: {
    width: '500px'
  },

  deleteButton: {
    color: 'red'
  }
};

class BillFormRow extends Component {
  state = {
    info: '',
    unit: '',
    qty: '',
    price: ''
  };

  getValue = category => {
    const variants = {
      'Инфо.': 'info',
      'Цена за ед.': 'unit',
      'Кол.': 'qty',
      Цена: 'price'
    };

    return variants[category];
  };

  setValue = (event, key) => {
    const { qty } = this.state;

    this.setState({
      [key]: event.target.value
    });

    if (key === 'unit' || key === 'qty' || key === 'price') {
      this.setState({
        price:
          key === 'unit'
            ? event.target.value * qty
            : key === 'price'
            ? event.target.value
            : this.state.unit * event.target.value
      });

      const { getSum, formKey } = this.props;
      getSum(
        key === 'unit'
          ? event.target.value * qty
          : key === 'price'
          ? event.target.value
          : this.state.unit * event.target.value || 0,
        formKey
      );
    }
  };

  getType = category => {
    const variants = {
      'Инфо.': 'text',
      'Цена за ед.': 'number',
      'Кол.': 'number',
      Цена: 'number'
    };

    return variants[category];
  };

  render() {
    const { classes, onDelete, formKey } = this.props;

    return (
      <Grid container alignItems="flex-end">
        {['Инфо.', 'Цена за ед.', 'Кол.', 'Цена'].map(category => {
          const isInfo = category === 'Инфо.';
          return (
            <TextField
              key={category}
              label={category}
              className={`${classes.billField} ${
                isInfo ? classes.infoField : ''
              }`}
              type={this.getType(category)}
              multiline={isInfo}
              rows={4}
              rowsMax={4}
              value={this.state[this.getValue(category)]}
              onChange={e => this.setValue(e, this.getValue(category))}
            />
          );
        })}
        <Button
          onClick={() => onDelete(formKey)}
          className={classes.deleteButton}
        >
          Удалить
        </Button>
      </Grid>
    );
  }
}

export default withStyles(styles)(BillFormRow);
