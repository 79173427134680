import React from 'react';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import API from '../API';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      marginTop: '9px',
      display: 'flex',
      alignItems: 'center',
      width: '100vw',
      height: '35px',
      background: 'white',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
    }
  },

  data: {
    marginRight: 'auto',
    marginLeft: '10px'
  },

  logout: {
    marginLeft: 'auto',
    marginRight: '10px',
    color: 'red'
  }
}));

const MobileHeader = props => {
  const classes = useStyles();
  const { displayName, isEmptyUser, history } = props;

  return isEmptyUser ? null : (
    <div className={classes.header}>
      <div className={classes.data}>{displayName}</div>
      <div
        className={classes.logout}
        onClick={() => {
          API.auth()
            .signOut()
            .then(() => {
              history.push('/login');
            });
        }}
      >
        Logout
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  displayName: state.firebase.auth.displayName,
  isEmptyUser: state.firebase.auth.isEmpty
});

export default compose(
  connect(mapStateToProps),
  withRouter
)(MobileHeader);
