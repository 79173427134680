import React, { Component } from 'react';
import {
  Grid,
  Typography,
  withStyles,
  TextField,
  Button
} from '@material-ui/core';

import { convertCamel } from '../utils';
import OrdersData from './OrdersData';
import { clone } from '../utils';
import API from '../API';

const styles = {
  mainGrid: {
    padding: '20px',
    flexDirection: 'column'
  },

  saveButton: {
    width: '50px'
  }
};

class PersonalData extends Component {
  state = {
    email: '',
    companyName: '',
    country: '',
    phone: '',
    skype: '',
    wechat: '',
    site: ''
  };

  componentDidMount() {
    const { userData } = this.props;

    this.setState({
      email: userData['email'] || '',
      companyName: userData['companyName'] || '',
      country: userData['country'] || '',
      phone: userData['phone'] || '',
      skype: userData['skype'] || '',
      wechat: userData['wechat'] || '',
      site: userData['site'] || ''
    });
  }

  setValue = (key, val) => {
    this.setState({
      [key]: val
    });
  };

  saveData = () => {
    const { userData, partner, updateData } = this.props;

    const db = API.firestore();
    const data = clone(this.state);

    if (!partner) {
      const { email, companyName, country, phone, skype, wechat } = data;

      db.collection('userDetails')
        .doc(userData.docId)
        .update({
          email,
          companyName,
          country,
          phone,
          skype,
          wechat
        })
        .then(() => updateData());
    } else {
      const { companyName, email, phone, site } = data;
      db.collection('partners')
        .doc(userData.docId)
        .update({
          companyName,
          email,
          phone,
          site
        })
        .then(() => updateData());
    }
  };

  render() {
    const { userData, classes, partner } = this.props;

    if (partner) {
      const { name, docId } = userData;

      return (
        <Grid container className={classes.mainGrid}>
          <Button
            variant="contained"
            color="primary"
            className={classes.saveButton}
            onClick={this.saveData}
          >
            Сохр.
          </Button>
          <Typography variant="subtitle1">{`Name: ${name}`}</Typography>
          {['companyName', 'email', 'phone', 'site'].map(category => {
            return (
              <Typography variant="subtitle1" key={category}>
                {`${convertCamel(category)}:`}
                <TextField
                  value={this.state[category]}
                  onChange={e => this.setValue(category, e.target.value)}
                />
              </Typography>
            );
          })}
          <OrdersData uid={docId} userData={userData} partner={partner} />
        </Grid>
      );
    } else {
      const { firstName, lastName, docId } = userData;

      return (
        <Grid container className={classes.mainGrid}>
          <Button
            variant="contained"
            color="primary"
            className={classes.saveButton}
            onClick={this.saveData}
          >
            Сохр.
          </Button>
          <Typography variant="subtitle1">
            {`Name: ${firstName} ${lastName}`}
          </Typography>
          {['email', 'companyName', 'country', 'phone', 'skype', 'wechat'].map(
            category => {
              return (
                <Typography variant="subtitle1" key={category}>
                  {`${convertCamel(category)}:`}
                  <TextField
                    value={this.state[category]}
                    onChange={e => this.setValue(category, e.target.value)}
                  />
                </Typography>
              );
            }
          )}
          <OrdersData uid={docId} />
        </Grid>
      );
    }
  }
}

export default withStyles(styles)(PersonalData);
