import React, { Component } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  withStyles
} from '@material-ui/core';

import AddRowForm from './AddRowForm';
import OfferTable from './OfferTable';

import API from '../API';
import { clone } from '../utils';

const styles = {
  mainContainer: {
    width: '100%',
    height: '100%',
    flexDirection: 'column'
  },

  margin: {
    margin: '0 5px 0 5px'
  },

  wideInput: {
    width: '200px'
  },

  inputContainer: {
    display: 'flex',
    alignItems: 'center'
  },

  loading: {
    width: '100%',
    height: 'calc(800px - 48px)',
    overflow: 'auto'
  },

  partnerContainer: {
    paddingTop: '10px',
    paddingBottom: '10px',
    borderBottom: '1px solid'
  },

  tableGrid: {
    padding: '10px'
  }
};

class TenderTab extends Component {
  state = {
    data: null
  };

  getData = () => {
    const { id } = this.props;

    const db = API.firestore();

    db.collection('orders')
      .where('id', '==', id)
      .get()
      .then(querySnap => {
        querySnap.forEach(doc => {
          const data = doc.data();

          this.setState({
            data
          });
        });
      });
  };

  componentDidMount() {
    this.getData();
  }

  saveTable = (index, length, cb) => {
    const { data } = this.state;
    const newData = clone(data);

    for (let i = 0; i < length; i += 1) {
      newData.products[index].offerList[i].userData = this[
        `ref${index}`
      ].current[`ref${i}`].current.state;
    }

    const db = API.firestore();

    db.collection('orders')
      .where('id', '==', newData.id)
      .get()
      .then(querySnap => {
        querySnap.forEach(doc => {
          db.collection('orders')
            .doc(doc.id)
            .update(newData);
        });
      });
  };

  deleteTender = (offerIndex, productIndex) => {
    const data = clone(this.state.data);

    data.products[productIndex].offerList.splice(offerIndex, 1);

    const db = API.firestore();

    db.collection('orders')
      .where('id', '==', data.id)
      .get()
      .then(querySnap => {
        querySnap.forEach(doc => {
          db.collection('orders')
            .doc(doc.id)
            .update(data)
            .then(() => {
              this.setState({
                data
              });
            });
        });
      });
  };

  getOffers = () => {
    const { data } = this.state;
    const { classes } = this.props;

    return data.products.map((product, index) => {
      if (product.offerList && product.offerList.length > 0) {
        this[`ref${index}`] = React.createRef();
        return (
          <Grid container className={classes.tableGrid} key={index}>
            <Grid container>
              <Typography variant="h6">{`Рейс ${index + 1}`}</Typography>
              <Button
                variant="contained"
                color="primary"
                className={classes.margin}
                onClick={() => this.saveTable(index, product.offerList.length)}
              >
                Сохр.
              </Button>
            </Grid>
            <OfferTable
              productIndex={index}
              data={data}
              getData={this.getData}
              ref={this[`ref${index}`]}
              product={product}
              deleteTender={this.deleteTender}
              save={() => this.saveTable(index, product.offerList.length)}
            />
          </Grid>
        );
      } else {
        return null;
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { data } = this.state;

    if (data === null) {
      return (
        <Grid
          className={classes.loading}
          container
          justify="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      );
    }

    return (
      <Grid className={classes.mainContainer}>
        <AddRowForm data={data} getData={this.getData} />
        {this.getOffers()}
      </Grid>
    );
  }
}

export default withStyles(styles)(TenderTab);
