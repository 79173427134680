import React, { Component } from 'react';
import {
  TableRow,
  TableCell,
  TextField,
  Button,
  CircularProgress,
  withStyles
} from '@material-ui/core';

import API from '../API';

const styles = {
  loading: {
    width: '93px',
    height: '36px'
  }
};

class PartnerAddForm extends Component {
  constructor(props) {
    super(props);

    this.initState = {
      companyName: '',
      partnerName: '',
      email: '',
      phone: '',
      site: '',
      isLoading: false
    };

    this.state = this.initState;
  }

  setValue = (val, key) => {
    this.setState({
      [key]: val
    });
  };

  addPartner = () => {
    const { partnerName, companyName, email, phone, site } = this.state;

    this.setState({
      isLoading: true
    });

    const { genData } = this.props;

    const db = API.firestore();

    db.collection('partners')
      .add({
        name: partnerName,
        email,
        phone,
        companyName,
        site
      })
      .then(() => {
        genData();
        this.setState(this.initState);
      });
  };

  render() {
    const { classes } = this.props;
    const { isLoading } = this.state;

    return (
      <TableRow>
        {['companyName', 'partnerName', 'email', 'phone', 'site', ''].map(
          field => {
            return (
              <TableCell key={field}>
                <TextField
                  value={this.state[field]}
                  onChange={e => this.setValue(e.target.value, field)}
                />
              </TableCell>
            );
          }
        )}
        <TableCell>
          {isLoading ? (
            <div className={classes.loading}>
              <CircularProgress size={36} />
            </div>
          ) : (
            <Button onClick={this.addPartner}>Добавить</Button>
          )}
        </TableCell>
      </TableRow>
    );
  }
}

export default withStyles(styles)(PartnerAddForm);
