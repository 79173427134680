import React, { Component } from 'react';
import {
  TextField,
  Grid,
  withStyles,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  FormLabel,
  FormControl,
  Radio,
  RadioGroup,
  AppBar,
  Tab,
  Tabs,
  OutlinedInput,
  InputLabel,
  FormHelperText
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import CartonForm from './CartonForm';

const styles = theme => ({
  textSize: {
    width: '70%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },

  disabledBorder: {
    '& > div.MuiInputBase-root.Mui-disabled': { color: 'rgba(0, 0, 0, 0.87)' }
  },

  marginBottom: {
    marginBottom: '15px'
  },

  photoName: {
    marginLeft: '10px',
    marginRight: '10px'
  },

  photoRemove: {
    cursor: 'pointer',
    fontSize: '20px',
    color: 'red'
  },

  checkboxErr: {
    color: 'red'
  },

  hidden: {
    display: 'none'
  },

  margins: {
    margin: '10px'
  },

  bottomCentered: {
    marginTop: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto'
  },

  helperText: {
    width: '200px'
  },

  cortonForm: {
    border: '1px solid grey'
  },

  addTab: {
    fontSize: 20,
    opacity: 1
  },

  closeIcon: {
    fontSize: '20px',
    visibility: 'hidden'
  },

  labelFix: {
    zIndex: 10000,
    background: 'white',
    padding: '0 2px 0 2px'
  },

  tabWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover svg': {
      visibility: 'visible'
    }
  },

  checkboxMobile: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start'
    }
  },

  cartonSizeMargin: {
    marginTop: '50px'
  },

  tabBar: {
    marginBottom: '10px'
  },

  mainGrid: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      '& .MuiGrid-root h5': {
        margin: 0
      },
      '& > .MuiGrid-root.MuiGrid-item div.MuiGrid-root.MuiGrid-container': {
        flexWrap: 'nowrap'
        // height: '65px',
        // marginBottom: '30px'
      }
    }
  }
});

class ProductForm extends Component {
  state = {
    productName: '',
    productNameErr: false,
    hsCode: '',
    hsCodeErr: false,
    invoiceAmount: '',
    invoiceAmountErr: false,
    shipTo: '',
    shipToErr: false,
    checkboxErr: false,
    ae1: false,
    ae2: false,
    ra: false,
    ms: false,
    rs: false,
    shippingTerms: 'FOB',
    photos: [],
    cartonCount: 1,
    cartonList: [0],
    maxCount: 1,
    currentTab: 0,
    cartonsData: [
      {
        cartonsQty: '',
        cartonsQtyErr: false,
        lErr: false,
        l: '',
        wErr: false,
        w: '',
        hErr: false,
        h: '',
        cartonWeightErr: false,
        cartonWeight: ''
      }
    ]
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked, checkboxErr: false });
  };

  setValue = (key, value) => {
    this.setState({
      [key]: value,
      [`${key}Err`]: false
    });
  };

  uploadPhotos = event => {
    const { photos } = this.state;

    if (event.target.files.length > 10 || photos.length > 10) {
      this.setState({
        errorMsg: 'Max photos: 10'
      });

      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      this.setState({
        photos: [...photos, ...event.target.files]
      });
    }
  };

  genPhotosName = () => {
    const { photos } = this.state;
    const { classes } = this.props;

    if (photos.length > 0) {
      return photos.map(photo => (
        <span key={photo.name} className={classes.photoName}>
          {photo.name}
          <Close
            onClick={() => this.photoRemove(photo.name)}
            className={classes.photoRemove}
          />
        </span>
      ));
    }
  };

  photoRemove = name => {
    const { photos } = this.state;
    const { disabled } = this.props;

    if (!disabled) {
      this.setState({
        photos: photos.filter(photo => photo.name !== name)
      });
    }
  };

  removeTab = index => {
    const { cartonCount } = this.state;
    const { disabled } = this.props;

    if (!disabled && cartonCount !== 1) {
      const { cartonList, currentTab, cartonsData } = this.state;
      const newList = [...cartonList];
      newList[index] = null;
      const newData = [...cartonsData];
      newData.splice(index, 1);

      const newState = {
        cartonList: newList,
        cartonCount: cartonCount - 1,
        currentTab:
          currentTab === cartonCount - 1 && currentTab !== 0
            ? currentTab - 1
            : currentTab,
        cartonsData: newData
      };

      this.setState(newState);

      setTimeout(() => {
        this.setState({
          currentTab: cartonCount - 2
        });
        delete this[`ref${index}`];
      }, 0);
    }
  };

  changeTab = (event, newValue) => {
    const { disabled } = this.props;
    if (!disabled) {
      this.setState({
        currentTab: newValue
      });
    }
  };

  setFormData = (formState, index) => {
    const { cartonsData } = this.state;
    const newData = [...cartonsData];
    newData[index] = formState;
    this.setState({
      cartonsData: newData
    });
  };

  genCartonForms = titles => {
    const { currentTab, cartonList } = this.state;
    const { classes, disabled } = this.props;
    const filteredList = cartonList.filter(carton => carton !== null);

    return filteredList.map((carton, index) => {
      if (titles) {
        return (
          <Tab
            label={
              <div className={classes.tabWrapper}>
                <span className={classes.tabName}>{`Carton ${index + 1}`}</span>
                <Close
                  onClick={() => this.removeTab(carton)}
                  className={classes.closeIcon}
                />
              </div>
            }
            key={`label-${index}`}
          />
        );
      } else {
        this[`ref${index}`] = React.createRef();

        return (
          <CartonForm
            ref={this[`ref${index}`]}
            hidden={currentTab !== index}
            disabled={disabled}
            key={`form-${carton}`}
            index={index}
            setFormData={this.setFormData}
            firstFormRef={this.ref0}
          />
        );
      }
    });
  };

  addTab = () => {
    const { cartonCount, cartonList, maxCount, cartonsData } = this.state;
    const { disabled } = this.props;

    if (!disabled) {
      const newList = [...cartonList];
      newList.push(maxCount);

      const newArr = [...cartonsData];
      newArr[cartonCount] = {
        cartonsQty: '',
        cartonsQtyErr: false,
        lErr: false,
        l: '',
        wErr: false,
        w: '',
        hErr: false,
        h: '',
        cartonWeightErr: false,
        cartonWeight: ''
      };

      this.setState({
        cartonList: newList,
        cartonCount: cartonCount + 1,
        maxCount: maxCount + 1,
        cartonsData: newArr
      });
    }
  };

  genTotalData = type => {
    const { cartonsData } = this.state;
    //(((l * w * h) / 10000000) * cartonsQty).toFixed(3)
    // Weigh - (cartonWeight * cartonsQty).toFixed(3)
    if (type === 'totalVolume') {
      let totalVolume = null;
      if (cartonsData.length > 1) {
        const result = [];
        for (let i = 0; i < cartonsData.length; i += 1) {
          const current = cartonsData[i];
          if (current.l && current.w && current.h && current.cartonsQty) {
            result.push(
              parseFloat(
                (
                  ((parseFloat(current.l) *
                    parseFloat(current.w) *
                    parseFloat(current.h)) /
                    1000000) *
                  current.cartonsQty
                ).toFixed(3)
              )
            );
          }
        }
        totalVolume =
          result.length > 0
            ? result.reduce((prev, cur) => prev + cur).toFixed(3)
            : 0;
        return totalVolume;
      } else {
        const current = cartonsData[0];
        totalVolume = (
          ((current.l * current.w * current.h) / 1000000) *
          current.cartonsQty
        ).toFixed(3);
        return totalVolume;
      }
    } else if (type === 'totalWeight') {
      let totalWeight = null;
      if (cartonsData.length > 1) {
        const result = [];
        for (let i = 0; i < cartonsData.length; i += 1) {
          const current = cartonsData[i];
          if (current.cartonWeight && current.cartonsQty) {
            result.push(
              parseFloat((current.cartonWeight * current.cartonsQty).toFixed(3))
            );
          }
        }
        totalWeight =
          result.length > 0
            ? result.reduce((prev, cur) => prev + cur).toFixed(3)
            : 0;
        return totalWeight;
      } else {
        const current = cartonsData[0];
        totalWeight = (current.cartonWeight * current.cartonsQty).toFixed(3);
        return totalWeight;
      }
    } else if (type === 'volumeWeight') {
      let volumeWeight = null;
      if (cartonsData.length > 1) {
        const result = [];
        for (let i = 0; i < cartonsData.length; i += 1) {
          const current = cartonsData[i];
          if (current.cartonWeight && current.cartonsQty) {
            result.push(
              ((current.l * current.w * current.h) / 6000) * current.cartonsQty
            );
          }
        }
        volumeWeight =
          result.length > 0
            ? result.reduce((prev, cur) => prev + cur).toFixed(3)
            : 0;
        return volumeWeight;
      } else {
        const current = cartonsData[0];
        volumeWeight = (current.cartonWeight * current.cartonsQty).toFixed(3);
        return volumeWeight;
      }
    } else if (type === 'volumeWeightExpress') {
      let volumeWeightExpress = null;
      if (cartonsData.length > 1) {
        const result = [];
        for (let i = 0; i < cartonsData.length; i += 1) {
          const current = cartonsData[i];
          if (current.cartonWeight && current.cartonsQty) {
            result.push(
              ((current.l * current.w * current.h) / 5000) * current.cartonsQty
            );
          }
        }
        volumeWeightExpress =
          result.length > 0
            ? result.reduce((prev, cur) => prev + cur).toFixed(3)
            : 0;
        return volumeWeightExpress;
      } else {
        const current = cartonsData[0];
        volumeWeightExpress = (
          current.cartonWeight * current.cartonsQty
        ).toFixed(3);
        return volumeWeightExpress;
      }
    }
  };

  componentDidMount() {
    const { firstFormRef, index } = this.props;

    if (index > 0) {
      this.setState(firstFormRef.current.state);
      this.setState({
        cartonCount: 1,
        cartonList: [0],
        maxCount: 1,
        currentTab: 0,
        cartonsData: [
          {
            cartonsQty: '',
            cartonsQtyErr: false,
            lErr: false,
            l: '',
            wErr: false,
            w: '',
            hErr: false,
            h: '',
            cartonWeightErr: false,
            cartonWeight: ''
          }
        ]
      });
    }
  }

  render() {
    const {
      productNameErr,
      hsCodeErr,
      invoiceAmountErr,
      shipToErr,
      productName,
      hsCode,
      invoiceAmount,
      shipTo,
      ae1,
      ae2,
      ra,
      ms,
      rs,
      checkboxErr,
      currentTab
    } = this.state;
    const { hidden, classes, index, shippingTerms, disabled } = this.props;

    return (
      <Grid
        container
        className={`${hidden ? classes.hidden : ''} ${classes.mainGrid}`}
      >
        <Grid md={6} sm={12} container direction="column" item>
          <TextField
            variant="outlined"
            margin="normal"
            required
            error={productNameErr}
            id={`productName-${index}`}
            label="Product Name"
            name="productName"
            autoComplete="productName"
            className={`${classes.textSize} ${classes.marginBottom}`}
            value={productName}
            onChange={event => this.setValue('productName', event.target.value)}
            autoFocus
            disabled={disabled}
            helperText="*название товара на английском и китайском"
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">Shipping Terms</FormLabel>
            <RadioGroup
              aria-label="position"
              name="position"
              value={shippingTerms}
              onChange={(e, val) => {
                this.setState({ shippingTerms: val });
              }}
              row
              defaultValue="FOB"
            >
              <FormControlLabel
                value="FOB"
                control={<Radio color="primary" />}
                label="FOB"
                disabled={disabled}
              />
              <FormControlLabel
                value="EXW"
                control={<Radio color="primary" />}
                label="EXW"
                disabled={disabled}
              />
            </RadioGroup>
          </FormControl>

          <FormControl variant="outlined" className={classes.textSize}>
            <InputLabel
              htmlFor={`hsCode-${index}`}
              className={classes.labelFix}
            >
              HS Code
            </InputLabel>
            <OutlinedInput
              required
              id={`hsCode-${index}`}
              label="HS Code"
              name="hsCode"
              error={hsCodeErr}
              autoComplete="hsCode"
              value={hsCode}
              onChange={event => this.setValue('hsCode', event.target.value)}
              disabled={disabled}
            />
            <FormHelperText>
              *таможенный код товара (спросить у производителя/найти на сайте{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://hts.usitc.gov/"
              >
                https://hts.usitc.gov/
              </a>
              )
            </FormHelperText>
          </FormControl>

          <TextField
            variant="outlined"
            margin="normal"
            type="number"
            required
            id={`invoiceAmount-${index}`}
            label="Invoice Amount(USD)"
            name="invoiceAmount"
            error={invoiceAmountErr}
            autoComplete="invoiceAmount"
            className={classes.textSize}
            disabled={disabled}
            value={invoiceAmount}
            onChange={event =>
              this.setValue('invoiceAmount', event.target.value)
            }
            helperText="*заявляемая стоимость партии товара"
          />
        </Grid>
        <Grid
          md={6}
          sm={12}
          container
          direction="column"
          className={classes.cortonForm}
          item
        >
          <AppBar
            position="static"
            className={classes.tabBar}
            color="secondary"
          >
            <Tabs
              value={currentTab}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="simple tabs example"
              onChange={this.changeTab}
            >
              {this.genCartonForms(true)}
              <Tab onClick={this.addTab} className={classes.addTab} label="+" />
            </Tabs>
          </AppBar>
          {this.genCartonForms()}
        </Grid>
        <Grid md={6} container direction="column" item>
          <TextField
            variant="outlined"
            disabled
            margin="normal"
            id={`totalWeight-${index}`}
            label="Total Weight(kg)"
            className={`${classes.textSize} ${classes.disabledBorder}`}
            value={parseFloat(this.genTotalData('totalWeight'))}
          />
        </Grid>
        <Grid
          md={6}
          sm={12}
          container
          direction="column"
          alignItems="center"
          item
        >
          <TextField
            variant="outlined"
            disabled
            margin="normal"
            id={`totalVolume-${index}`}
            label="Total Volume(CBM)"
            className={`${classes.textSize} ${classes.disabledBorder}`}
            value={parseFloat(this.genTotalData('totalVolume'))}
          />
        </Grid>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth={true}
          id={`shipTo-${index}`}
          label="Ship To"
          multiline
          name="shipTo"
          disabled={disabled}
          autoComplete="shipTo"
          className={classes.fullWidth}
          value={shipTo}
          error={shipToErr}
          onChange={event => this.setValue('shipTo', event.target.value)}
          helperText="*адрес доставки. Если склад Амазон, то еще необходим код склада (пример: FTW1)"
        />
        <Grid container justify="center" direction="column">
          <Typography
            component="h4"
            variant="h6"
            align="center"
            className={classes.margins}
          >
            Shipping Method
          </Typography>
          <Grid container justify="center" className={classes.checkboxMobile}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={ae1}
                  color="primary"
                  id={`ae1-${index}`}
                  disabled={disabled}
                  onChange={this.handleChange('ae1')}
                  className={`${checkboxErr ? classes.checkboxErr : ''}`}
                />
              }
              label="Air Express (3-5 Days)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={ae2}
                  id={`ae2-${index}`}
                  disabled={disabled}
                  color="primary"
                  onChange={this.handleChange('ae2')}
                  className={`${checkboxErr ? classes.checkboxErr : ''}`}
                />
              }
              label="Air Express (5-7 Days)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={ra}
                  id={`ra-${index}`}
                  color="primary"
                  disabled={disabled}
                  className={`${checkboxErr ? classes.checkboxErr : ''}`}
                  onChange={this.handleChange('ra')}
                />
              }
              label="Regular Air (10-12 Days)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={ms}
                  id={`ms-${index}`}
                  disabled={disabled}
                  color="primary"
                  className={`${checkboxErr ? classes.checkboxErr : ''}`}
                  onChange={this.handleChange('ms')}
                />
              }
              label="Matson Sea (21-24 Days)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={rs}
                  id={`rs-${index}`}
                  disabled={disabled}
                  color="primary"
                  className={`${checkboxErr ? classes.checkboxErr : ''}`}
                  onChange={this.handleChange('rs')}
                />
              }
              label="Regular Sea (30-35 Days)"
            />
          </Grid>
          <Grid>
            <Typography
              className={classes.margins}
              component="h5"
              variant="h6"
              align="center"
            >
              Product Photos
            </Typography>
            <input
              accept="image/*"
              hidden
              id={`raised-button-file-${index}`}
              multiple
              type="file"
              disabled={disabled}
              onChange={this.uploadPhotos}
            />
            <label htmlFor={`raised-button-file-${index}`}>
              <Button
                variant="contained"
                component="span"
                className={classes.button}
              >
                Attach...
              </Button>
            </label>
            {this.genPhotosName()}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ProductForm);
